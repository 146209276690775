import Profile1 from "../../assets/images/select-profile-1.png";
import Profile2 from "../../assets/images/select-profile-2.png";
import Profile3 from "../../assets/images/select-profile-3.png";
import Profile4 from "../../assets/images/select-profile-4.png";
import Profile5 from "../../assets/images/select-profile-5.png";
import Profile6 from "../../assets/images/select-profile-6.png";
import Profile7 from "../../assets/images/select-profile-7.png";
import Profile8 from "../../assets/images/select-profile-8.png";
import Profile9 from "../../assets/images/select-profile-9.png";

const styleProfileIcon = { width: "50px", borderRadius: "16px" };

export const ImageArray = {
  1: <img src={Profile1} alt="" style={styleProfileIcon} />,
  2: <img src={Profile2} alt="" style={styleProfileIcon} />,
  3: <img src={Profile3} alt="" style={styleProfileIcon} />,
  4: <img src={Profile4} alt="" style={styleProfileIcon} />,
  5: <img src={Profile5} alt="" style={styleProfileIcon} />,
  6: <img src={Profile6} alt="" style={styleProfileIcon} />,
  7: <img src={Profile7} alt="" style={styleProfileIcon} />,
  8: <img src={Profile8} alt="" style={styleProfileIcon} />,
  9: <img src={Profile9} alt="" style={styleProfileIcon} />,
};