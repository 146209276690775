import { env2 } from "../Helpers/Instance";
import { endPoint } from "./EndPoint";
import {
  getResultAndShowSuccess,
  getMsgResult,
  getMsgResultIOC,
} from "./ResponseValidation";
import AxiosFetch from "./FetchServices";

const IOC_CREATED = "IoC validation created successful"; //Desarrollo
const USER_DELETED = "IoC validation deleted successful"; //Desarrollo

export async function addIOCAsync(IOCData) {
  //Desarrollo
  const url = env2.api + endPoint.addIOCExec;
  const response = await AxiosFetch("POST", url, null, JSON.stringify(IOCData));
  const resultOk = getResultAndShowSuccess(response, IOC_CREATED);
  return resultOk;
}

export async function getIOCAsyncID(id) {
  //Desarrollo
  const url = env2.api + endPoint.getIOC + id;
  const response = await AxiosFetch("GET", url);
  return getMsgResult(response);
}
export async function getIOResultCAsyncID(id) {
  //Desarrollo
  const url = env2.api + endPoint.getResultIOC + id;
  const response = await AxiosFetch("GET", url);
  return getMsgResultIOC(response);
}

export async function deleteIOC(IOCid) {
  //Desarrollo
  const url = env2.api + endPoint.desactiveIOC + IOCid;
  const response = await AxiosFetch("GET", url);
  return getResultAndShowSuccess(response, USER_DELETED);
}
