import { endPoint } from "./EndPoint";
import { env2 } from "../Helpers/Instance";
import { getMsgResult, getResultAndShowSuccess } from "./ResponseValidation";
import AxiosFetch from "./FetchServices";

const EXECUTION_ENVIROMENT_UPDATED =
  "Execution enviroment updated successfully";
const ISOLATION_IPS_UPDATED = "Policies updated correctly";
const TOKEN_UPDATED = "Token updated successfully";
const EMAIL_UPDATED = "Email updated successfully";
const URL_UPDATED = "C2 Url updated successfully";
const EMAIL_TESTED = "Check your email";

export async function GetEnviromentAsymc() {
  const url = env2.api + endPoint.enviroments;
  const response = await AxiosFetch("GET", url);
  const result = getMsgResult(response);
  return result;
}
export async function GetIsolationIPDataAsymc() {
  const url = env2.api + endPoint.isolationIp;
  const response = await AxiosFetch("GET", url);
  const result = getMsgResult(response);
  return result;
}

export async function UpdateExecutionEnviromentAsync(bodyData) {
  const url = env2.api + endPoint.updateExecutionEnviroment;
  const jsonBody = JSON.stringify(bodyData);
  const response = await AxiosFetch("POST", url, null, jsonBody);
  const result = getResultAndShowSuccess(
    response,
    EXECUTION_ENVIROMENT_UPDATED
  );
  return result;
}

export async function UpdateIps(bodyData) {
  const url = env2.api + endPoint.updateIsolationEnviroment;
  const jsonBody = JSON.stringify(bodyData);
  const response = await AxiosFetch("POST", url, null, jsonBody);
  const result = getResultAndShowSuccess(response, ISOLATION_IPS_UPDATED);
  return result;
}
export async function DeleteGroupIp(bodyData) {
  const url = env2.api + endPoint.updateIsolationEnviroment;
  const jsonBody = JSON.stringify(bodyData);
  const response = await AxiosFetch("POST", url, null, jsonBody);
  const result = getMsgResult(response);
  return result;
}
export async function UpdateEnviromentToken(bodyData) {
  const url = env2.api + endPoint.updateTokenEnviroment;
  const jsonBody = JSON.stringify(bodyData);
  const response = await AxiosFetch("POST", url, null, jsonBody);
  const result = getResultAndShowSuccess(response, TOKEN_UPDATED);
  return result;
}

export async function UpdateEmailEnviroment(bodyData) {
  const url = env2.api + endPoint.updateEmailEnviroment;
  const jsonBody = JSON.stringify(bodyData);
  const response = await AxiosFetch("POST", url, null, jsonBody);
  const result = getResultAndShowSuccess(response, EMAIL_UPDATED);
  return result;
}

export async function UpdateUrlEnviroment(bodyData) {
  const url = env2.api + endPoint.updateUrlEnviroment;
  const jsonBody = JSON.stringify(bodyData);
  const response = await AxiosFetch("POST", url, null, jsonBody);
  const result = getResultAndShowSuccess(response, URL_UPDATED);
  return result;
}

export async function TestEnviromentEmail(data) {
  const url = env2.api + endPoint.testEmailEnviroment;
  const jsonBody = JSON.stringify(data);
  const response = await AxiosFetch("POST", url, null, jsonBody);
  const result = getResultAndShowSuccess(response, EMAIL_TESTED);
  return result;
}
