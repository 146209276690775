import React, { useCallback, useEffect, useMemo, useState } from "react";
import Cookies from "universal-cookie";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Modal,
  TablePagination,
  TextField,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import "./AgentStyles.scss";
import DiagramIcon from "../../assets/images/world-icon.svg";
import DiagramAgent from "../../assets/images/diagramAgent.svg";
import ForceDirectedTree from "../../Scripts/AgentForceDirectedTree";
import AgentTree from "./Diagram";
import {
  pageTheme,
  StyledDataGrid,
  CustomLoadingOverlay,
  QuickSearchToolbar,
  escapeRegExp,
} from "../../Helpers/Themes";
import { GetClientReportAsync } from "../../Services/Reports";
import { GridOverlay } from "@mui/x-data-grid-pro";
import { criticalColors } from "./Common/Helpers/FunctionsHelpers";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ConnexionIcon from "../../assets/images/connected-icon.svg";
import DesconnexionIcon from "../../assets/images/not-connected-icon.svg";

import { ArticleRounded, DesktopMacTwoTone } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { NotifyInfo } from "../../Helpers/Scripts";
import { env2 } from "../../Helpers/Instance";
import { endPoint } from "../../Services/EndPoint";
import axios from "axios";
import AxiosFetch from "../../Services/FetchServices";
import ExporExcelMiddle from "../../Helpers/ExporExcelMiddle";

export default function DiagramAgentTable(props) {
  const { Reload, AskLogFiles } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [rowslength, setrowslength] = React.useState(0);
  const [openDownloadAgents, setopenDownloadAgents] = useState(false);
  const [DataChild, setDataChild] = useState();
  const [exportData, setexportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalRows, settotalRows] = useState([]);
  const [page, setPage] = useState(0);
  const [sortModel, setSortModel] = useState([
    { field: "online", sort: "desc" },
  ]);
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array
  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    cookies.set("CurrentPageDataPagination", "14", {  path: "/",
      SameSite: "none",
      secure: true,});

    async function GetData() {
      setLoading(true);
      const url = env2.api + endPoint.clients;
      const response = await AxiosFetch("GET", url, source);
      if (response?.msg && response.msg.query) {
        let array = [];
        response.msg.result.forEach((element) => {
          let OS = 0;
          OS =
            element.osInformation[0].name.toUpperCase().search("WIN") !== -1
              ? 1
              : OS;
          OS = element.osInformation[0].name.search("Linux") !== -1 ? 2 : OS;
          OS = element.osInformation[0].name.search("Mac") !== -1 ? 3 : OS;
          array.push({
            id: element._id,
            online: element.online,
            isolation: element.online ? element.isolation : false,
            hostname: element?.hostname
              ? element.hostname
              : element.workUser[0].hostname,
            ip:
              element.IPInformation.length > 0
                ? element.IPInformation[0].public.IP
                : "N/A",
            private:
              element.IPInformation.length > 0
                ? element.IPInformation[0].private.IP
                : "N/A",
            port:
              element.IPInformation.length > 0
                ? element.IPInformation[0].port
                : "N/A",
            hardwareInformation: element.hardwareInformation,
            osInformation: element.osInformation,
            OS: OS,
            workUser: element.workUser,
            files: [],
            fileRequest: false,
            platform: element.osInformation[0].platform,
            version: element.osInformation[0].name,
            CPUName: element.hardwareInformation[0].CPUName,
            alias: element?.alias
              ? element.alias
              : element.hostname
              ? element.hostname
              : element.workUser[0].hostname,
          });
        });
        setRows(array);
        settotalRows(array);
        setrowslength(array.length);

        let expo = {
          data: array,
          colums: [
            { label: "Endpoint", value: "hostname" },
            { label: "Operating System", value: "platform" },
            { label: "System Version", value: "version" },
            { label: "Processor", value: "CPUName" },
          ],
        };
        setexportData(expo);
        setLoading(false);
      } else {
        setRows([]);
        settotalRows(0);
        setrowslength(0);

        let expo = {
          data: [],
          colums: [
            { label: "Endpoint", value: "hostname" },
            { label: "Operating System", value: "platform" },
            { label: "System Version", value: "version" },
            { label: "Processor", value: "CPUName" },
          ],
        };
        setexportData(expo);
        setLoading(false);
      }
    }
    GetData();

    return () => {
      source.cancel();
    };
  }, [Reload, cookies]);

  const CloseModal = () => {
    setopenDownloadAgents(false);
  };

  const generateHistoric = async (data) => {
    const response = await GetClientReportAsync(data.id);
    if (response) {
      let name = data?.hostname ? data.hostname : data.workUser[0].hostname;
      setopenDownloadAgents(true);
      ForceGrapichConstructor(response, name);
    }
  };

  const ForceGrapichConstructor = (array, name) => {
    let Childrens = [];
    let executionChildren = [];
    let packChildren = [];
    array.client.executions.forEach((execution) => {
      if (execution.Reports.length > 0) {
        executionChildren = [];
        execution.Reports.forEach((report, index) => {
          if (report.historys.packages.length > 0) {
            report.historys.packages.forEach((pack) => {
              if (pack.artifacts.length > 0) {
                packChildren = [];
                pack.artifacts.forEach((artifact) => {
                  packChildren.push({
                    name: artifact.name.slice(0, 8) + "...",
                    tooltip: artifact.name,
                    value: 30,
                    color: criticalColors(artifact.threatType),
                  });
                });
              }
              executionChildren.push({
                name: pack.name.slice(0, 10) + "...",
                tooltip: pack.name,
                value: 50,
                color: "#e328af",
                children: packChildren,
              });
            });
          }
        });
      }
      Childrens.push({
        value: 70,
        color: "#9f30f6",
        name: execution.name.slice(0, 15) + "...",
        tooltip: execution.name,
        children: executionChildren,
      });
    });
    let data = [
      {
        name: name.slice(0, 15) + "...",
        tooltip: array.hostname,
        children: Childrens,
        value: 100,
        color: "#25BEC1",
      },
    ];

    setDataChild(data);
    ForceDirectedTree(data);
  };

  const Columns = [
    {
      field: "online",
      headerName: "Online",
      flex: 0.5,
      minWidth: 50,
      align: "center",
      type: "boolean",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          <DesktopMacTwoTone style={{ verticalAlign: "middle" }} />
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.value ? (
            <Box>
              <Tooltip title="Connected">
                <img
                  src={ConnexionIcon}
                  alt=""
                  style={{ marginRight: "15px" }}
                />
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <Tooltip title="Disconnected">
                <img
                  src={DesconnexionIcon}
                  alt=""
                  style={{ marginRight: "15px" }}
                />
              </Tooltip>
            </Box>
          )}
        </>
      ),
    },
    {
      field: "alias",
      headerName: "Endpoint",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "platform",
      headerName: "Operating system",
      flex: 0.5,
      minWidth: 100,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          Operating system
        </Box>
      ),
    },
    {
      field: "osInformation",
      headerName: "System version",
      flex: 1,
      minWidth: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => <>{params.row.osInformation[0].name}</>,
    },
    {
      field: "hardwareInformation",
      headerName: "Processor",
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 100,
      align: "center",
      renderCell: (params) => <>{params.row.hardwareInformation[0].CPUName}</>,
    },
    {
      field: "grap",
      headerName: "Graphic",
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Graphic</Box>
      ),
      renderCell: (params) => (
        <IconButton onClick={() => generateHistoric(params.row)}>
          <img src={DiagramIcon} alt="" />
        </IconButton>
      ),
    },
    {
      field: "logs",
      headerName: "Logs",
      sortable: false,
      flex: 1,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          Logs
        </Box>
      ),
      renderCell: (params) => (
        <>
          {endpointSelect === params.id ? (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                renderInput={(props) => <TextField {...props} />}
                onChange={setCalendar}
                value={Calendar}
                label="Choose a day"
                disableFuture
              />
            </LocalizationProvider>
          ) : (
            <Tooltip title="Download Logs">
              <span>
                <IconButton
                  disabled={!params.row.online}
                  onClick={selectEndpoint.bind(this, params.id)}
                >
                  <ArticleRounded />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      ),
    },
  ];
  const [endpointSelect, setendpointSelect] = useState("");
  const [Calendar, setCalendar] = useState(null);

  const selectEndpoint = (id) => {
    setendpointSelect(id);
    setCalendar(null);
  };
  const AskLogs = useCallback(() => {
    const day = new Date(Calendar);
    const yyyyMMdd =
      day.getFullYear() +
      "-" +
      (day.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      day.getDate().toString().padStart(2, "0");
    let Request = { Client: endpointSelect, Date: yyyyMMdd };
    setRows((x) => {
      let array = [...x];
      for (let index = 0; index < array.length; index++) {
        if (array[index].id === selectEndpoint) {
          array[index].fileRequest = true;
        }
      }
      return array;
    });
    setendpointSelect("");
    setCalendar(null);
    AskLogFiles(Request);
  }, [AskLogFiles, Calendar, endpointSelect]);
  useEffect(() => {
    if (Calendar && endpointSelect !== "") {
      let date = new Date();
      if (Calendar > date) {
        NotifyInfo("Available files from past days and today");
      } else {
        AskLogs();
      }
    }
  }, [Calendar, AskLogs, endpointSelect]);

  const requestSearch = (searchValue) => {
    setPage(0);
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = totalRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
    let NoTotal = filteredRows.length;
    setrowslength(NoTotal);
    if (searchValue === "") {
      setRows(totalRows);
      let NoTotal = totalRows.length;
      setrowslength(NoTotal);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  return (
    <>
      <Box style={{ width: "100%" }}>
        <Modal open={openDownloadAgents} onClose={CloseModal}>
          <Box className="Section BackImage">
            <Typography style={{ fontSize: "20px", fontWeight: "500" }}>
              Prior 10 Emulations
            </Typography>
            <Box
              style={{
                border: "1px solid #1e1c3a",
                padding: "15px",
                boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
                borderRadius: "12px",
                marginTop: "24px",
                minHeight: "300px",
              }}
            >
              <AgentTree Data={DataChild} />
            </Box>
          </Box>
        </Modal>
        <ThemeProvider theme={pageTheme}>
          <Typography variant="pageTitle">
            <img src={DiagramAgent} alt="" className="rt-icon-header" />
            Endpoints Diagram
            <Box style={{ float: "right" }}>
              <ExporExcelMiddle
                pageOrigin={"AgentDiagram"}
                externalData={exportData}
                fileName="E.V.E. Endpoints Export"
              />
            </Box>
          </Typography>
          <Box
            style={{
              width: "100%",
              backgroundColor: "#242933",
              border: "1px 1px 0 1px solid #1e1c3a",
              padding: "15px",
              boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
              borderRadius: "12px 12px 0 0 ",
              marginTop: "24px",
            }}
          >
            <div style={{ height: "auto", width: "100%" }}>
              <StyledDataGrid
                components={{
                  Toolbar: QuickSearchToolbar,
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: () => (
                    <GridOverlay>
                      <div>No Data Results</div>
                    </GridOverlay>
                  ),
                  NoResultsOverlay: () => (
                    <GridOverlay>
                      <div>No Results Filter Data</div>
                    </GridOverlay>
                  ),
                  Pagination: () => (
                    <>
                      <TablePagination
                        component="div"
                        page={page}
                        count={rowslength}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[5, 10, 20]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                      />
                    </>
                  ),
                }}
                rows={rows}
                columns={Columns}
                pagination
                pageSize={rowsPerPage}
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={rowslength}
                page={page}
                onPageChange={handleChangePage}
                onPageSizeChange={(PerPage) => setRowsPerPage(PerPage)}
                disableSelectionOnClick
                loading={loading}
                density="comfortable"
                autoHeight
                sortModel={sortModel}
                onSortModelChange={(newSort) => handleSortModelChange(newSort)}
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(""),
                  },
                }}
              />
            </div>
          </Box>
        </ThemeProvider>
      </Box>
    </>
  );
}
