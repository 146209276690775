import {
  GetEnviromentAsymc,
  GetIsolationIPDataAsymc,
} from "../../../../Services/Enviroment";
import { v4 as uuidv4 } from "uuid";
import { ColumnsIP } from "../constants/ExportConstants";

export async function GetDataInformationIsolationIpsService(dataUser) {
  const responsedataIpsIsolation = await GetIsolationIPDataAsymc(
    dataUser.token
  );
  let ips = [];
  if (responsedataIpsIsolation) {
    responsedataIpsIsolation.forEach((group) => {
      group.IPs.forEach((element, index) => {
        ips.push({
          group: group.fk_subGroup.name,
          ip: element,
          id: uuidv4(),
        });
      });
    });
  }
  return ips;
}
//Get Server IP and C2 IP
export async function getDefaultIps() {
  const response = await GetEnviromentAsymc();
  let IPSDefualt = [];
  if (response) {
    response.forEach((element) => {
      if (element.name === "SYSTEM") {
        element.settings.forEach((set) => {
          if (set.variable === "IPADDRESS") {
            IPSDefualt.push(set.value);
          }
          if (set.variable === "C2") {
            IPSDefualt.push(set.value);
          }
        });
      }
    });
  }
  return IPSDefualt;
}

//API to get Isolation Groups
export async function GetIsolationIpsGroups() {
  const response = await GetIsolationIPDataAsymc();
  let groups = [];
  if (response) {
    response.forEach((group) => {
      let datagroup = {
        id: group.fk_subGroup.name + "-" + uuidv4(),
        name: group.fk_subGroup.name,
        ips: [],
        check: false,
      };
      group.IPs.forEach((element) => {
        datagroup.ips.push({
          ip: element,
          id: uuidv4(),
          check: false,
        });
      });
      groups.push(datagroup);
    });
  }
  return groups;
}

//Function to generate export data array
export function exportDataGenerate(array) {
  let Ips = [];
  array.forEach((element) => {
    element.ips.forEach((subElement) => {
      Ips.push({
        group: element.name,
        ip: subElement.ip,
      });
    });
  });
  let exportData = { data: Ips, colums: ColumnsIP };
  return exportData;
}

//validates the existence of the name in the group
export function checkGroupName(name, groupList) {
  let boolCheck = false;

  for (let index = 0; index < groupList.length; index++) {
    const element = groupList[index];

    if (element.name === name) {
      boolCheck = true;
      break;
    }
  }

  return boolCheck;
}
// function sintax ip correct
export function checkISintax(ip) {
  let regex =
    /^(25[0-5]|2[0-9][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-9][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-9][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-9][0-9]|[01]?[0-9][0-9]?)$/;
  return regex.exec(ip);
}
//Function ip duplicate in group
export function checkIPInGroup(ip, ipList) {
  let validate = true;
  for (let index = 0; index < ipList.length; index++) {
    const element = ipList[index];
    if (ip === element.ip) {
      validate = false;
      break;
    }
  }
  return validate;
}

//Funtion to validate info from txt file
export function checkFileIp(data, ipList) {
  let array = data.split("\r\n");
  let sintaxError = 0;
  let existsInGroup = 0;
  let duplicateIPinFile = 0;
  let filterList = [];
  array.forEach((element) => {
    if (checkISintax(element)) {
      if (checkIPInGroup(element, ipList)) {
        if (checkIPInGroup(element, filterList)) {
          filterList.push(element);
        } else {
          duplicateIPinFile++;
        }
      } else {
        existsInGroup++;
      }
    } else {
      sintaxError++;
    }
  });
  let dataReturn = {
    sintaxError: sintaxError,
    existsInGroup: existsInGroup,
    duplicateIPinFile: duplicateIPinFile,
    filterList: filterList,
  };
  return dataReturn;
}
export function ipListIsChecked(ipList) {
  let almustOne = false;
  for (let index = 0; index < ipList.length; index++) {
    const element = ipList[index];
    if (element.check) {
      almustOne = true;
      break;
    }
  }

  return almustOne;
}
export function handleCheckList(isChecked, ipList, defaultIPS) {
  ipList.forEach((element) => {
    let indexOf = defaultIPS.indexOf(element.ip);
    if (indexOf === -1) {
      element.check = isChecked;
    }
  });

  return ipList;
}
export function handleCheckListGroup(isChecked, groupList) {
  groupList.forEach((element) => {
    if (element.name !== "Default") {
      element.check = isChecked;
    }
  });

  return groupList;
}
