import { useEffect, useMemo, useState } from "react";
import Cookies from "universal-cookie";
import Typography from "@mui/material/Typography";
import { Box, Chip, IconButton, ThemeProvider } from "@mui/material";
import Icon from "../../assets/images/environment-top-icn.svg";
import detailsIcon from "../../assets/images/eye-icon.svg";

import {
  QuickSearchToolbar,
  pageTheme,
  StyledDataGrid,
  CustomLoadingOverlay,
  escapeRegExp,
} from "../../Helpers/Themes";
import ModelTimeParameters from "./ModelTimeParameters/ModelTimeParameters";
import ModelBase from "./ModelBase/ModelBase";
import ModelToken from "./ModelToken/ModelToken";
import ModelEmail from "./ModelEmail/ModelEmail";
import ModelURL from "./ModelURL/ModelURL";
import {
  AlternateEmail,
  AttachEmail,
  Gite,
  ListAlt,
  Power,
  RocketLaunch,
  Storage,
  Token,
} from "@mui/icons-material";
import GroupsTable from "./ModelIP/GroupsTable";
import axios from "axios";
import { endPoint } from "../../Services/EndPoint";
import { env2 } from "../../Helpers/Instance";
import AxiosFetch from "../../Services/FetchServices";

export default function SetupEnviroment(props) {
  const { DataUser, ReloadEnviroments } = props;
  const [rows, setRows] = useState([]);
  const [totalRows, settotalRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultIPS, setdefaultIPS] = useState([]);
  const [activeTableGroups, setActiveTableGroups] = useState(false);
  const rowsPerPage = 10;
  const [searchText, setSearchText] = useState("");
  const [signalReload, setsignalReload] = useState(0);
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array

  const Reload = () => {
    setsignalReload(signalReload + 1);
  };
  const ReloadTimes = () => {
    setsignalReload(signalReload + 1);
    ReloadEnviroments();
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = totalRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);

    if (searchValue === "") {
      setRows(totalRows);
    }
  };
  const columns = [
    {
      field: "name",
      headerName: "Setting",
      flex: 1,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%", fontWeight: "600" }}>
          Settings
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.row.name === "ISOLATION" ? (
            <Chip
              style={{ minWidth: "150px" }}
              variant="outlined"
              icon={<ListAlt />}
              label="Isolation"
            />
          ) : params.row.name === "EXECUTIONS" ? (
            <Chip
              style={{ minWidth: "150px" }}
              variant="outlined"
              icon={<RocketLaunch />}
              label="Emulation"
            />
          ) : params.row.name === "TOKEN" ? (
            <Chip
              style={{ minWidth: "150px" }}
              variant="outlined"
              icon={<Token />}
              label="Token"
            />
          ) : params.row.name === "EMAIL" ? (
            <Chip
              style={{ minWidth: "150px" }}
              variant="outlined"
              icon={<AttachEmail />}
              label="Email"
            />
          ) : params.row.name === "URL" ? (
            <Chip
              style={{ minWidth: "150px" }}
              variant="outlined"
              icon={<AlternateEmail />}
              label="Address"
            />
          ) : params.row.name === "SERVER" ? (
            <Chip
              style={{ minWidth: "150px" }}
              variant="outlined"
              icon={<Storage />}
              label="Server"
            />
          ) : params.row.name === "SOCKET" ? (
            <Chip
              style={{ minWidth: "150px" }}
              variant="outlined"
              icon={<Power />}
              label="Socket"
            />
          ) : params.row.name === "SYSTEM" ? (
            <Chip
              style={{ minWidth: "150px" }}
              variant="outlined"
              icon={<Gite />}
              label="System"
            />
          ) : null}
        </>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      renderCell: (params) => (
        <>
          {params.row.name === "ISOLATION" ? (
            <Typography>Configuring the isolation list</Typography>
          ) : params.row.name === "EXECUTIONS" ? (
            <Typography>
              Configuring emulation and scripts parameters
            </Typography>
          ) : params.row.name === "TOKEN" ? (
            <Typography>
              Values associated with the lifetime of the token and its
              activation
            </Typography>
          ) : params.row.name === "EMAIL" ? (
            <Typography>
              Mail configurations for technical support and testing
            </Typography>
          ) : params.row.name === "URL" ? (
            <Typography>
              Values associated with the C2 server and email restore
            </Typography>
          ) : params.row.name === "SERVER" ? (
            <Typography>
              Values associated with connection ports and instance ID
            </Typography>
          ) : params.row.name === "SOCKET" ? (
            <Typography>
              The Socket IO configuration parameters and the number of allowed
              agents are displayed
            </Typography>
          ) : params.row.name === "SYSTEM" ? (
            <Typography>
              The domain name and IP address of the service are displayed
            </Typography>
          ) : null}
        </>
      ),
    },
    {
      field: "settings",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%", fontWeight: "600" }}>
          Actions
        </Box>
      ),
      renderCell: (params) => (
        <>
          {DataUser.user.result.rol !== "admin" ? (
            <ModelBase DataSettings={params.row} />
          ) : (
            <>
              {params.row.name === "ISOLATION" ? (
                // <ModelIP
                //   DataSettings={params.row}
                //   DataUser={DataUser}
                //   Reload={Reload}
                //   defaultIPS={defaultIPS}
                // />
                <IconButton
                  onClick={() => {
                    setActiveTableGroups(true);
                  }}
                >
                  <img src={detailsIcon} alt="" />
                </IconButton>
              ) : params.row.name === "EXECUTIONS" ? (
                <ModelTimeParameters
                  DataSettings={params.row.settings}
                  DataUser={DataUser}
                  Reload={ReloadTimes}
                />
              ) : params.row.name === "TOKEN" ? (
                <ModelToken
                  DataSettings={params.row.settings}
                  DataUser={DataUser}
                  Reload={Reload}
                />
              ) : params.row.name === "EMAIL" ? (
                <ModelEmail
                  DataSettings={params.row.settings}
                  DataUser={DataUser}
                  Reload={Reload}
                />
              ) : params.row.name === "URL" ? (
                <ModelURL
                  DataSettings={params.row.settings}
                  DataUser={DataUser}
                  Reload={Reload}
                />
              ) : (
                <ModelBase DataSettings={params.row} />
              )}
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    cookies.set("CurrentPageDataPagination", "7", {  path: "/",
      SameSite: "none",
      secure: true,});

    async function GetData() {
      setLoading(true);
      const url = env2.api + endPoint.enviroments;
      const response = await AxiosFetch("GET", url, source);
      if (response?.msg && response.msg.query) {
        let array = [];
        let IPSDefualt = [];
        response.msg.result.forEach((element) => {
          if (element.name === "SYSTEM") {
            element.settings.forEach((set) => {
              if (set.variable === "IPADDRESS") {
                IPSDefualt.push(set.value);
              }
              if (set.variable === "C2") {
                IPSDefualt.push(set.value);
              }
            });
          }
          if (element.name !== "SERVER") {
            array.push({
              id: element._id ? element._id : Math.floor(Math.random() * 100),
              name: element.name,
              description: element.description,
              settings: element.settings,
            });
          }
        });
        array.push({
          id: Math.floor(Math.random() * 100),
          name: "ISOLATION",
          description: "Edit isolation ips",
          settings: [],
        });
        setdefaultIPS(IPSDefualt);
        setRows(array);
        settotalRows(array);
      } else {
        setRows([]);
        settotalRows([]);
      }
    }
    GetData();
    setLoading(false);

    return () => {
      source.cancel();
    };
  }, [cookies, signalReload]);

  const EnviromentsTable = (
    <div style={{ height: "auto", width: "100%" }}>
      <StyledDataGrid
        components={{
          Toolbar: QuickSearchToolbar,
          LoadingOverlay: CustomLoadingOverlay,
        }}
        rows={rows}
        columns={columns}
        pageSize={rowsPerPage}
        disableSelectionOnClick
        loading={loading}
        density="comfortable"
        autoHeight
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
          },
        }}
      />
    </div>
  );

  return (
    <Box style={{ width: "100%" }}>
      <ThemeProvider theme={pageTheme}>
        {!activeTableGroups ? (
          <>
            <Typography variant="pageTitle">
              <img src={Icon} alt="" className="rt-icon-header" />
              Environment Setup
            </Typography>

            <div
              style={{
                width: "100%",
                backgroundColor: "#242933",
                border: " 1px solid #090f1a",
                padding: "15px",
                boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
                borderRadius: "14px",
                marginTop: "22px",
              }}
            >
              {EnviromentsTable}
            </div>
          </>
        ) : (
          <GroupsTable
            DataUser={DataUser}
            defaultIPS={defaultIPS}
            ReturnPage={() => {
              setActiveTableGroups(false);
            }}
          />
        )}
      </ThemeProvider>
    </Box>
  );
}
