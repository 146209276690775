import React, { useCallback, useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TablePagination,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import IsolationIcon from "../../assets/images/isolation-network.svg";
import Cookies from "universal-cookie";

import ExecutionIcon from "../../assets/images/execution-icon.svg";
import ConnexionIcon from "../../assets/images/connected-icon.svg";
import DesconnexionIcon from "../../assets/images/not-connected-icon.svg";
import {
  ActionButton,
  QuickSearchToolbar,
  pageTheme,
  SecondaryButton,
  StyledDataGrid,
  CustomLoadingOverlay,
  escapeRegExp,
} from "../../Helpers/Themes";

import { DesktopMacTwoTone } from "@mui/icons-material";
import { saveAs } from "file-saver";
import { NotifySuccess } from "../../Helpers/Scripts";
import { GridOverlay } from "@mui/x-data-grid-pro";
import "./isolationNetwork.scss";
import AxiosFetch from "../../Services/FetchServices";
import { env2 } from "../../Helpers/Instance";
import { endPoint } from "../../Services/EndPoint";
import axios from "axios";

export default function IsolationTable(props) {
  const { Files, FileRecive, Reload } = props;
  const [rows, setRows] = useState([]);
  const [totalRows, settotalRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [sortModel, setSortModel] = useState([
    { field: "online", sort: "desc" },
  ]);
  const [textFile, settextFile] = useState();
  const [openModal, setopenModal] = useState(false);
  const [page, setPage] = useState(0);
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array

  const download = () => {
    if (openModal === true) {
      var save = textFile;
      var blob = new Blob([save], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, "IsolationLog.txt");
      NotifySuccess("Export Success!");
    }
  };

  const checkedList = useCallback((list) => {
    const cleandataExt = list?.map((element) => {
      return element.replace(".txt", "").replace(/\b(\d{1})\b/g, "0$1");
    });
    const dateSort = cleandataExt?.sort((a, b) => {
      var aa = a.split("-").reverse().join(),
        bb = b.split("-").reverse().join();
      return aa > bb ? -1 : aa < bb ? 1 : 0;
    });
    if (list !== null) {
      setRows((x) => {
        let array = [...x];
        for (let index = 0; index < array.length; index++) {
          if (array[index].fileRequest) {
            array[index].files = dateSort;
            array[index].fileRequest = false;
          }
        }
        return array;
      });
    }
  }, []);

  useEffect(() => {
    if (FileRecive !== null) {
      settextFile(FileRecive);
      setopenModal(true);
    }
  }, [FileRecive, cookies]);

  useEffect(() => {
    checkedList(Files);
  }, [Files, checkedList]);

  const AskFiles = (id) => {
    setRows((x) => {
      let array = [...x];
      for (let index = 0; index < array.length; index++) {
        array[index].files = [];
        array[index].fileRequest = false;
        if (array[index].id === id) {
          array[index].fileRequest = true;
        }
      }
      return array;
    });
    props.AskFiles(id);
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    cookies.set("CurrentPageDataPagination", "9", {  path: "/",
      SameSite: "none",
      secure: true,});

    const url = env2.api + endPoint.onlineClients;
    async function GetData() {
      const response = await AxiosFetch("GET", url, source);
      setLoading(true);
      let array = [];
      if (response?.msg && response.msg.query) {
        let onlineClientsResponse = response.msg.result;
        onlineClientsResponse.forEach((element) => {
          let OS = 0;
          OS =
            element.osInformation[0].name.toUpperCase().search("WIN") !== -1
              ? 1
              : OS;
          OS = element.osInformation[0].name.search("Linux") !== -1 ? 2 : OS;
          OS = element.osInformation[0].name.search("Mac") !== -1 ? 3 : OS;
          let ippublic = element.IPInformation[0].public.IP.split("::ffff:");
          array.push({
            id: element._id,
            online: element.online,
            isolation: element.online ? element.isolation : false,
            hostname: element?.hostname
              ? element.hostname
              : element.workUser[0].hostname,
            ip: element.IPInformation.length > 0 ? ippublic : "N/A",
            private:
              element.IPInformation.length > 0
                ? element.IPInformation[0].private.IP
                : "N/A",
            port:
              element.IPInformation.length > 0
                ? element.IPInformation[0].port
                : "N/A",
            actions: element,
            OS: OS,
            files: [],
            fileRequest: false,
          });
        });
        setCount(array.length);
        setRows(array);
        settotalRows(array);
        setLoading(false);
      } else {
        setCount(0);
        setRows([]);
        settotalRows([]);
        setLoading(false);
      }
    }
    GetData();

    return () => {
      source.cancel();
    };
  }, [Reload, cookies]);

  const handleChange = (event) => {
    let data = event.split("/");
    let date = data[1].split("-");
    let month = parseInt(date[1], 10) > 9 ? date[1] : date[1].substring(1, 2);
    let day = parseInt(date[0], 10) > 9 ? date[0] : date[0].substring(1, 2);

    let Request = {
      Client: data[0],
      File: day + "-" + month + "-" + date[2] + ".txt",
    };
    props.GetFile(Request);
  };
  const Closemodal = () => {
    settextFile("");
    setopenModal(false);
    props.CleanText();
  };
  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = totalRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
    let NoTotal = filteredRows.length;
    setCount(NoTotal);
    if (searchValue === "") {
      setRows(totalRows);
      let NoTotal = totalRows.length;
      setCount(NoTotal);
    }
  };

  const columns = [
    {
      field: "online",
      headerName: "Online",
      flex: 0.5,
      minWidth: 50,
      align: "center",
      type: "boolean",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          <DesktopMacTwoTone style={{ verticalAlign: "middle" }} />
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.value ? (
            <Box>
              <Tooltip title="Connected">
                <img
                  src={ConnexionIcon}
                  alt=""
                  style={{ marginRight: "15px" }}
                />
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <Tooltip title="Disconnected">
                <img
                  src={DesconnexionIcon}
                  alt=""
                  style={{ marginRight: "15px" }}
                />
              </Tooltip>
            </Box>
          )}
        </>
      ),
    },
    {
      field: "hostname",
      headerName: "Host name",
      flex: 0.5,
      minWidth: 150,

      renderCell: (params) => (
        <Typography
          style={{
            color: params.row.online ? "green" : "rgba(255,255,255,0.65)",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "private",
      headerName: "Private IP address",
      flex: 0.5,
      minWidth: 150,

      renderCell: (params) => (
        <Typography
          style={{
            color: params.row.online ? "green" : "rgba(255,255,255,0.65)",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "ip",
      headerName: "Public IP address",
      flex: 0.5,
      minWidth: 150,

      renderCell: (params) => (
        <Typography
          style={{
            color: params.row.online ? "green" : "rgba(255,255,255,0.65)",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "port",
      headerName: "Port",
      flex: 0.2,
      minWidth: 100,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Port</Box>
      ),
      renderCell: (params) => (
        <Typography
          style={{
            color: params.row.online ? "green" : "rgba(255,255,255,0.65)",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      minWidth: 100,
      align: "center",
      type: "actions",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Actions</Box>
      ),
      renderCell: (params) => (
        <>
          {params.row.files.length > 0 ? (
            <FormControl fullWidth>
              <Select
                defaultValue="List of isolation logs"
                sx={{
                  ".MuiSelect-select": {
                    backgroundColor: "transparent !important",
                  },
                }}
              >
                <MenuItem
                  value="List of isolation logs"
                  selected="true"
                  disabled="disabled"
                >
                  List of isolation logs
                </MenuItem>
                {params.row.files.map((elem) => (
                  <MenuItem value={elem}>
                    <ActionButton
                      style={{ width: "90%", height: "30px" }}
                      onClick={handleChange.bind(this, params.id + "/" + elem)}
                    >
                      {elem}
                    </ActionButton>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <IconButton
              disabled={params.row.OS !== 1}
              onClick={AskFiles.bind(this, params.id)}
            >
              <img src={ExecutionIcon} alt="" />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <>
      <Modal open={openModal} onClose={Closemodal}>
        <Box
          className="Section"
          style={{
            display: "inline",
            backgroundColor: "#242933",
            border: "1px solid #090f1a",
            boxShadow: "18px 4px 35px rgba(0, 0, 0, 0.15)",
            borderRadius: "14px",
          }}
        >
          <Typography
            variant="body1"
            style={{
              maxHeight: "600px",
              overflowY: "auto",
              overflowX: "hidden",
              whiteSpace: "pre-wrap",
            }}
          >
            {textFile}
          </Typography>
          <SecondaryButton style={{ margin: "auto" }} onClick={download.bind()}>
            Download Log
          </SecondaryButton>
        </Box>
      </Modal>
      <ThemeProvider theme={pageTheme}>
        <Box style={{ width: "100%" }}>
          <Typography variant="pageTitle">
            <img src={IsolationIcon} alt="" className="rt-icon-header" />
            Isolation network
          </Typography>
          <Box
            style={{
              width: "100%",
              backgroundColor: "#242933",
              border: " 1px solid #090f1a",
              padding: "15px",
              boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
              borderRadius: "14px",
              marginTop: "24px",
              height: "auto",
            }}
          >
            <div style={{ height: "auto", width: "100%" }}>
              <StyledDataGrid
                components={{
                  Toolbar: QuickSearchToolbar,
                  LoadingOverlay: CustomLoadingOverlay,
                  NoRowsOverlay: () => (
                    <GridOverlay>
                      <div>No Data Results</div>
                    </GridOverlay>
                  ),
                  NoResultsOverlay: () => (
                    <GridOverlay>
                      <div>No Results Filter Data</div>
                    </GridOverlay>
                  ),
                  Pagination: () => (
                    <>
                      <TablePagination
                        component="div"
                        page={page}
                        count={count}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[5, 10, 20]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                      />
                    </>
                  ),
                }}
                rows={rows}
                columns={columns}
                pagination
                pageSize={rowsPerPage}
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={count}
                page={page}
                onPageChange={handleChangePage}
                sortModel={sortModel}
                onSortModelChange={(newSort) => handleSortModelChange(newSort)}
                onPageSizeChange={(PerPage) => setRowsPerPage(PerPage)}
                disableSelectionOnClick
                loading={loading}
                density="comfortable"
                autoHeight
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(""),
                  },
                }}
              />
            </div>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
