import { Box, Button, Modal } from "@mui/material";
import { useState } from "react";
import { GetArtifactByIdAsync } from "../../../../Services/Artifacts";
import "./Advance.scss";
import GeneralInfo from "./ArtifactGeneral";

export default function ModalAdvanceInformation(props) {
    const { ArtifactGlobalData, typeStatus } = props;
    const [open, setOpen] = useState(false);
    const [ArtifactData, setArtifactData] = useState(null);

    const handleOpen = async () => {
        const response = await GetArtifactByIdAsync(ArtifactGlobalData._id);
        if (response) {
                setArtifactData(response);
                setOpen(true);
            }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <Box className="ModalSettion">
            <GeneralInfo ArtifactGlobalData={ArtifactGlobalData} ArtifactData={ArtifactData} typeStatus={typeStatus}/>
        </Box>

    );

    return (
        <Box className="ModalBox">
            <Button
                className="ModalButtonInterpretation"
                onClick={handleOpen}
            >
                ACTIONS
            </Button>
            <Modal open={open} onClose={handleClose}>
                {body}
            </Modal>
        </Box>
    );
}