import {
  AppBar,
  Box,
  Chip,
  Grid,
  IconButton,
  Modal,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  // Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import SucessIcon from "../../../assets/images/success-table-icon.svg";
import FailIcon from "../../../assets/images/fail-table-icon.svg";
import { PrimaryButton } from "../../../Helpers/Themes";
import {
  getIOCAsyncID,
  getIOResultCAsyncID,
} from "../../../Services/IOCExecution";
import SwipeableViews from "react-swipeable-views";
import { ConvertDateUtc } from "../../../Helpers/GlobalData";
import { ListIOCs } from "../ConstantIOCs";
import Eye from "../../../assets/images/eye-icon.svg";
import ConnexionIcon from "../../../assets/images/connected-icon.svg";
import DesconnexionIcon from "../../../assets/images/not-connected-icon.svg";
import { Info, Summarize } from "@mui/icons-material";
import { parseInt } from "lodash-es";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  backgroundColor: "#1e1c3a",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#c59d48",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#c59d48",
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(0),
    color: "rgba(255, 255, 255, 0.7)",
    backgroundColor: "#090f1a",
    "&.Mui-selected": {
      color: "#fff",
      backgroundColor: "#090f1a",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  })
);

export default function ModalAdvanceInfo(props) {
  const { ExecutionInfo } = props;
  const [open, setOpen] = useState(false);
  const [InfoData, setInfoData] = useState([]);
  const [ExecuteInfo, setExecuteInfo] = useState([]);
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [InfoShow, setInfoShow] = useState("");
  const [Selected, setSelected] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleOpen = async () => {
    const response = await getIOCAsyncID(ExecutionInfo.id);

    if (response) {
      setInfoData(response);
      if (response.isTest === "tested") {
        await getInfoExect();
      }
      setOpen(true);
    }
  };
  const getInfoExect = async () => {
    const responseResult = await getIOResultCAsyncID(
      ExecutionInfo.id
    );
    if (responseResult) {
      setExecuteInfo(responseResult);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleInfo = (data) => {
    setInfoShow(data.response.data);

    setSelected(ListIOCs[parseInt(data.task) - 1].name);
  };

  const Execution = (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Box className="GeneralContent">
          {/* <PrimaryButton
            style={{ float: "right", marginTop: "-1px", marginRight: "15px" }}
          >
            Download Report
          </PrimaryButton> */}
          <Typography>Name : {ExecuteInfo.name}</Typography>
          <Typography>
            Started IoC Validation at :{" "}
            {ConvertDateUtc(ExecuteInfo.lastModification)}
          </Typography>
        </Box>
      </Grid>
      {Selected === "" ? (
        <Grid item md={12}>
          <Box className="GeneralContent">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">IoC</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ExecuteInfo?.results
                  ? ExecuteInfo.results.map((Element) => {
                      return (
                        <TableRow
                          onClick={handleInfo.bind(this, Element)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell align="center">
                            {ListIOCs[parseInt(Element.task) - 1].name}
                          </TableCell>
                          <TableCell align="center">
                            {Element.isSuccess === "Y" ? (
                              <img alt="" src={SucessIcon} />
                            ) : (
                              <img alt="" src={FailIcon} />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </Box>
        </Grid>
      ) : (
        <Grid item md={12}>
          <Box className="GeneralContent">
            <PrimaryButton
              style={{ float: "right" }}
              onClick={() => {
                setSelected("");
              }}
            >
              Back
            </PrimaryButton>
            <Typography>Interpretation of the IoC selected</Typography>
            <Typography>{"Command " + Selected}</Typography>
            <Typography
              style={{
                maxWidth: "100%",
                wordBreak: "break-all",
                textAlign: "justify",
                color: "rgb(102, 99, 143)",
                whiteSpace: "pre-wrap",
              }}
            >
              {InfoShow}
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
  const Details = (
    <Grid container spacing={1}>
      <Grid item md={6}>
        <Box className="GeneralContent">
          <Typography>Name : {InfoData.name}</Typography>
          <Typography>
            Tag Status :{" "}
            {InfoData.isTest === "tested" ? (
              <Chip
                style={{ width: "200px" }}
                variant="outlined"
                label="Tested"
                color="success"
              />
            ) : (
              <Chip
                style={{ width: "200px" }}
                variant="outlined"
                label="Untested"
                color="error"
              />
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box className="GeneralContent">
          <Typography>
            Client :
            {InfoData?.client ? InfoData.client.workUser[0].hostname : "N/A"}
            {InfoData?.client ? (
              <>
                {InfoData.client.online ? (
                  <Box style={{ marginLeft: "15px", display: "inline-block" }}>
                    <img src={ConnexionIcon} alt="" />
                  </Box>
                ) : (
                  <Box style={{ marginLeft: "15px", display: "inline-block" }}>
                    <img src={DesconnexionIcon} alt="" />
                  </Box>
                )}
              </>
            ) : null}
          </Typography>
          <Typography>
            Last Modification : {ConvertDateUtc(InfoData.updatedAt)}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={12}>
        <Box className="GeneralContent">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">IoC Validation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {InfoData?.tasks
                ? InfoData.tasks.map((Element) => {
                    return (
                      <TableRow>
                        <TableCell align="center">
                          {"Command " +
                            Element.task +
                            " : " +
                            ListIOCs[parseInt(Element.task) - 1].name}{" "}
                          {Element?.arguments[0]
                            ? " / Argument PID " + Element.arguments[0]
                            : null}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </Box>
      </Grid>
    </Grid>
  );

  const TabsContent = (
    <Box className="ModalSettion">
      <AppBar position="static">
        <StyledTabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <StyledTab
            icon={<Info />}
            iconPosition="start"
            label="General Details"
            {...a11yProps(0)}
          />
          {InfoData.isTest === "tested" ? (
            <StyledTab
              icon={<Summarize />}
              iconPosition="start"
              label="Detailed Report"
              {...a11yProps(1)}
            />
          ) : null}
        </StyledTabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ backgroundColor: "#0a0818" }}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {Details}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {Execution}
        </TabPanel>
      </SwipeableViews>
    </Box>
  );

  return (
    <Box className="ModalBox">
      {/* <Tooltip title="Details"> */}
        <IconButton className="iconButtonSize" onClick={handleOpen}>
          <img src={Eye} className="mr-3" alt="" />
        </IconButton>
      {/* </Tooltip> */}
      <Modal open={open} onClose={handleClose}>
        {TabsContent}
      </Modal>
    </Box>
  );
}
