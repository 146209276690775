import React, { useEffect, useMemo } from "react";
import { Box, Typography, Grid, ThemeProvider } from "@mui/material";
import about1 from "../../assets/images/about-1.png";
import about2 from "../../assets/images/about-2.png";
import about3 from "../../assets/images/about-3.png";
import AboutIcon from "../../assets/images/about-us.svg";
import "./About.scss";
import { pageTheme } from "../../Helpers/Themes";
import PackManGameEVE from "./EasterEggs/PacManGameEVE/PackManGameEVE";
import Cookies from "universal-cookie";

const titlesPageAboutUs = {
  titlePage: "About Us",
  titleTecnologyCombining: "Combining Technology & Innovation",
  titleTecnology: "Our Technology",
  titleHistory: "Our Story",
};

export default function AboutUS() {
  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    cookies.set("CurrentPageDataPagination", "15", {  path: "/",
      SameSite: "none",
      secure: true,});
  }, [cookies]);

  return (
    <ThemeProvider theme={pageTheme}>
      <Box>
        <Typography variant="pageTitle">
          <img src={AboutIcon} alt="" className="rt-icon-header" />
          {titlesPageAboutUs.titlePage}
        </Typography>

        <Box className="con-box-style">
          <Box style={{ marginBottom: "16px" }}>
            <Grid
              item
              md={4}
              className="con-bdr-grid"
              style={{ display: "inline-block" }}
            >
              <Box
                style={{
                  paddingRight: "16px",
                }}
              >
                <img src={about1} alt="" />
              </Box>
            </Grid>
            <Grid
              item
              md={8}
              style={{ display: "inline-block", verticalAlign: "top" }}
            >
              <Box className="container-about">
                <Typography variant="h5" style={{ marginBottom: "10px" }}>
                  {titlesPageAboutUs.titleTecnologyCombining}
                </Typography>
                <Typography className="about-header" style={{ margin: "26x" }}>
                  Epiphany Validation Engine believes in the pragmatic
                  evaluation and measurement of cybersecurity solutions in
                  charge of safeguarding companies and institutions from
                  security threats and possible targeted attacks. We utilize
                  artifact intelligence to carry out the evaluation of
                  cybersecurity systems and solutions.
                </Typography>
              </Box>
            </Grid>
          </Box>

          <Box style={{ marginBottom: "16px" }}>
            <Grid
              item
              md={4}
              className="con-bdr-grid"
              style={{ display: "inline-block" }}
            >
              <Box
                style={{
                  paddingRight: "16px",
                }}
              >
                <img src={about2} alt="" />
              </Box>
            </Grid>
            <Grid
              item
              md={8}
              style={{
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              <Box className="container-about">
                <Typography variant="h5" style={{ marginBottom: "10px" }}>
                  {titlesPageAboutUs.titleTecnology}
                </Typography>
                <Typography className="about-header">
                  We have put special dedication into developing the backend of
                  our solution in order to provide our clients on-demand testing
                  for new generations of advanced threats. In addition, we also
                  provide unknown artifacts for testing cybersecurity solutions,
                  and security processes that should be followed by security
                  professionals. We pride ourselves on being the pioneers in
                  developing test-oriented unknown artifacts.
                </Typography>
              </Box>
            </Grid>
          </Box>

          <Box style={{ marginBottom: "16px" }}>
            <Grid item md={4} style={{ display: "inline-block" }}>
              <Box
                style={{
                  paddingRight: "16px",
                }}
              >
                <img src={about3} alt="" />
              </Box>
            </Grid>
            <Grid
              item
              md={8}
              style={{
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              <Box className="container-about">
                <Typography variant="h5" style={{ marginBottom: "10px" }}>
                  {titlesPageAboutUs.titleHistory}
                </Typography>
                <Typography className="about-header">
                  After years of development and working in the field, the
                  founders of Epiphany Validation Engine identified the needs of
                  pragmatic evaluation against possible attacks and security
                  breaches with zero-day artifacts. Epiphany Validation Engine
                  was born as a response to these needs. We developed a platform
                  with the latest technologies that is capable of providing a
                  real testing environment under the corporate security of our
                  clients. Our mission is to help clients prevent cyber attacks
                  that are both known and, most importantly, unknown.
                </Typography>
                <PackManGameEVE />
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
