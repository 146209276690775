import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Modal,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import {
  ActionButton,
  ColorlibConnector,
  ColorlibStepReport,
  PrimaryButton,
  SecondaryButton,
} from "../../../Helpers/Themes";
import ExcelIcon from "../../../assets/images/excel-icon.svg";
import SwordIcon from "../../../assets/images/sword.svg";
import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  PictureAsPdfTwoTone,
  SecurityTwoTone,
} from "@mui/icons-material";
import html2canvas from "html2canvas";
import "./PDF.scss";
import { getDataReportCustomHistory } from "../../../Services/Reports";
import HelperDataPdfHistory from "../../../Helpers/ExportPdf/HelperDataPdfHistory/HelperDataPdfHistory";
import { ExportExcel } from "./ServicesReport";
import {
  excelDetailData,
  excelExecutiveData,
  pdfExecutiveData,
} from "./DefaultData";
import ExporExcelMiddle from "../../../Helpers/ExporExcelMiddle";

const styleBox = {
  backgroundColor: "#0a0818",
  borderRadius: "14px",
  margin: "7px",
  padding: "15px",
};

const styleCheckBox = {
  color: "#c59d48",
  marginRight: "7px",
};
const styleCheckBoxUnCheck = {
  color: "#c59d4866",
  marginRight: "7px",
};

const styleCheckBoxDisable = {
  color: "#0a0818",
  marginRight: "7px",
};

export default function ModalWizard(props) {
  const { importData, fileName, idExecution, ReportData, DateSelect } = props;
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [typeExport, settypeExport] = useState(0);
  const [typeMode, settypeMode] = useState(0);
  const [typeData, settypeData] = useState(0);
  const [visibleLoad, setvisibleLoad] = useState(false);
  const [valueDataPDF, setValueDataPDF] = useState(excelDetailData);
  const [exportExcelData, setExportExcelData] = useState([]);
  const [dataService, setDataService] = useState();

  const getDataHistoryCustom = useCallback(async () => {
    const body = {
      extend: ["endpoints", "actions", "urlMitre"],
      date: DateSelect.date,
      time: DateSelect.time,
      utc: DateSelect.utc,
    };
    const response = await getDataReportCustomHistory(idExecution, body);
    const dataresponse = HelperDataPdfHistory(
      response,
      typeMode === 0 ? true : false,
      ReportData
    );
    setDataService(dataresponse);
    return response;
  }, [DateSelect, ReportData, idExecution, typeMode]);

  const generateExcel = useCallback(
    (value) => {
      let data = ExportExcel(
        value === 0 ? true : false,
        ReportData.tabledata1,
        ReportData.vector
      );
      setExportExcelData(data);
    },
    [ReportData]
  );

  useEffect(() => {
    getDataHistoryCustom();
  }, [getDataHistoryCustom]);

  useEffect(() => {
    if (activeStep === 2) {
      generateExcel(typeMode);
      settypeData(0);
      let array = typeExport === 0 ? excelExecutiveData : pdfExecutiveData;
      setValueDataPDF(array);
    }
  }, [activeStep, generateExcel, typeMode, typeExport]);

  useEffect(() => {
    if (typeExport === 0) {
      if (typeData === 1) {
        let action = valueDataPDF[9];
        let defaultData = importData.data;
        if (action.checked === true) {
          importData.colums = [
            { label: "Client", value: "client" },
            { label: "File", value: "file" },
            { label: "StartGMT", value: "start" },
            { label: "FinishGMT", value: "finish" },
            { label: "Network", value: "networkSecurity" },
            { label: "Endpoint", value: "epp" },
            { label: "Execution", value: "executedFile" },
            { label: "C2", value: "C2" },
            { label: "Emulation Status", value: "satisfactory" },
            { label: "Package", value: "packageName" },
            { label: "actions", value: "actions" },
          ];

          importData.data.forEach((item) => {
            dataService.MITIGATIONS.forEach((i) =>
              item.file === i.Name ? (item.actions = i.Description) : null
            );
          });
        } else {
          importData.colums = [
            { label: "Client", value: "client" },
            { label: "File", value: "file" },
            { label: "StartGMT", value: "start" },
            { label: "FinishGMT", value: "finish" },
            { label: "Network", value: "networkSecurity" },
            { label: "Endpoint", value: "epp" },
            { label: "Execution", value: "executedFile" },
            { label: "C2", value: "C2" },
            { label: "Emulation Status", value: "satisfactory" },
            { label: "Package", value: "packageName" },
          ];
          importData.data = defaultData;
        }
      }
    }
  }, [typeExport, typeData, importData, valueDataPDF, dataService]);

  const handleOpen = async () => {
    setOpen(true);
    setValueDataPDF(excelDetailData);
  };

  const handleClose = () => {
    setValueDataPDF(excelDetailData);
    setActiveStep(0);
    settypeExport(0);
    settypeMode(0);
    settypeData(0);
    setOpen(false);
    setvisibleLoad(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const DownloadSelect = async () => {
    setvisibleLoad(true);
    const response = await getDataHistoryCustom();
    const screen = await Promise.resolve(screenShot("life-cycle"));
    const screen2 = await Promise.resolve(screenShot("DataListTypes"));
    const dataresponse = HelperDataPdfHistory(
      response,
      typeMode === 0 ? true : false,
      ReportData
    );
    let array = {
      data: dataresponse,
      checks: valueDataPDF,
      image: screen.toDataURL("image/png"),
      imagetypes: screen2.toDataURL("image/png"),
    };
    if (typeData === 0) {
      props.ExportPDF(array);
      handleClose();
    } else {
      props.ExportPDFDetails(array);
      handleClose();
    }
  };

  const handleCheckDataPdf = (name) => {
    let array = [...valueDataPDF];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (element.label === name) {
        element.checked = !element.checked;
        break;
      }
    }
    setValueDataPDF(array);
  };
  const handleType = (number) => {
    settypeData(number);
    const excelDetail = [
      {
        label: "Resume",
        checked: true,
      },
      {
        label: "FileLevelResults",
        checked: true,
      },
      {
        label: "EndPoints",
        checked: false,
      },
      {
        label: "Packages",
        checked: false,
      },
      {
        label: "ListofArtifacts",
        checked: false,
      },
      {
        label: "ArtifactsDetails",
        checked: false,
      },
      {
        label: "Interpretation",
        checked: false,
      },
      {
        label: "MITREDescription",
        checked: false,
      },
      {
        label: "AttackLifeCycle",
        checked: false,
      },
      {
        label: "Actions",
        checked: true,
      },
      {
        label: "Referents",
        checked: false,
      },
    ];

    const pdfDetail = [
      {
        label: "Resume",
        checked: true,
      },
      {
        label: "FileLevelResults",
        checked: true,
      },
      {
        label: "EndPoints",
        checked: true,
      },
      {
        label: "Packages",
        checked: true,
      },
      {
        label: "ListofArtifacts",
        checked: true,
      },
      {
        label: "ArtifactsDetails",
        checked: true,
      },
      {
        label: "Interpretation",
        checked: true,
      },
      {
        label: "MITREDescription",
        checked: true,
      },
      {
        label: "AttackLifeCycle",
        checked: true,
      },
      {
        label: "Actions",
        checked: true,
      },
      {
        label: "Referents",
        checked: false,
      },
    ];

    let array = typeExport === 0 ? excelDetail : pdfDetail;
    setValueDataPDF(array);
  };

  const stepperMain = (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      <Step key={0}>
        <StepLabel StepIconComponent={ColorlibStepReport}>
          <Typography style={{ color: "#fff" }}>Type:</Typography>
        </StepLabel>
      </Step>
      <Step key={1}>
        <StepLabel StepIconComponent={ColorlibStepReport}>
          <Typography style={{ color: "#fff" }}>Mode:</Typography>
        </StepLabel>
      </Step>
      <Step key={2}>
        <StepLabel StepIconComponent={ColorlibStepReport}>
          <Typography style={{ color: "#fff" }}>Information:</Typography>
        </StepLabel>
      </Step>
      <Step key={3}>
        <StepLabel StepIconComponent={ColorlibStepReport}>
          <Typography style={{ color: "#fff" }}>Export Info:</Typography>
        </StepLabel>
      </Step>
    </Stepper>
  );

  const selectType = (
    <Box style={styleBox}>
      <Grid container spacing={0}>
        <Grid item md={6}>
          <Card
            onClick={() => {
              settypeExport(0);
            }}
            sx={{
              color: "#ffff !important",
              cursor: "pointer",
              textAlign: "center",
              margin: "auto",
              backgroundColor: typeExport === 0 ? "#c59d48" : "transparent",
            }}
          >
            <Box className="ContainerIconExecution">
              <Tooltip title="Excel">
                <img
                  src={ExcelIcon}
                  alt="IconExcel"
                  className="IconExecution"
                />
              </Tooltip>
            </Box>
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                Excel
              </Typography>
              <Typography
                style={{ color: "#fff" }}
                variant="body2"
                color="text.secondary"
              >
                Export the File Level Results Table to a .xlsx format
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card
            onClick={() => {
              settypeExport(1);
            }}
            sx={{
              color: "#ffff !important",
              cursor: "pointer",
              textAlign: "center",
              margin: "auto",
              backgroundColor: typeExport === 1 ? "#c59d48" : "transparent",
            }}
          >
            <Box className="ContainerIconExecution">
              <Tooltip title="PDF">
                <PictureAsPdfTwoTone className="IconExecution" />
              </Tooltip>
            </Box>
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                PDF
              </Typography>
              <Typography
                style={{ color: "#fff" }}
                variant="body2"
                color="text.secondary"
              >
                Export data of emulation to a report in .pdf format
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
  const selectMode = (
    <Box style={styleBox}>
      <Grid container spacing={0}>
        <Grid item md={6}>
          <Card
            onClick={() => {
              settypeMode(0);
            }}
            sx={{
              color: "#ffff !important",
              cursor: "pointer",
              textAlign: "center",
              margin: "auto",
              backgroundColor: typeMode === 0 ? "#c59d48" : "transparent",
            }}
          >
            <Box className="ContainerIconExecution">
              <Tooltip title="Attack">
                <img
                  src={SwordIcon}
                  alt="IconSword"
                  className="IconExecution"
                />
              </Tooltip>
            </Box>
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                Attack
              </Typography>
              <Typography
                style={{ color: "#ffff" }}
                variant="body2"
                color="text.secondary"
              >
                See result from an Offensive perspective
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card
            onClick={() => {
              settypeMode(1);
            }}
            sx={{
              color: "#ffff !important",
              cursor: "pointer",
              textAlign: "center",
              margin: "7px",
              backgroundColor: typeMode === 1 ? "#c59d48" : "transparent",
            }}
          >
            <Box className="ContainerIconExecution">
              <Tooltip title="Defense">
                <SecurityTwoTone className="IconExecution" />
              </Tooltip>
            </Box>
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                Defense
              </Typography>
              <Typography
                style={{ color: "#ffff" }}
                variant="body2"
                color="text.secondary"
              >
                See result from an Defensive perspective
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );

  const selectData = (
    <Box
      sx={{
        "& .MuiFormControlLabel-label.Mui-disabled": {
          color: "gray !important",
        },
      }}
      style={styleBox}
    >
      <Grid container>
        <Grid style={{ justifyContent: "center" }} md={5} textAlign="center">
          {typeData === 0 ? (
            <ActionButton fullWidth>Executive</ActionButton>
          ) : (
            <SecondaryButton
              fullWidth
              onClick={() => {
                settypeData(0);
                setValueDataPDF(
                  typeExport === 0 ? excelExecutiveData : pdfExecutiveData
                );
              }}
            >
              Executive
            </SecondaryButton>
          )}
        </Grid>
        <Grid item md={1} />
        <Grid item md={5} textAlign="center">
          {typeData === 1 ? (
            <ActionButton fullWidth>Detailed</ActionButton>
          ) : (
            <SecondaryButton
              fullWidth
              onClick={() => {
                handleType(1);
              }}
            >
              Detailed
            </SecondaryButton>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography display={"inline-flex"} sx={{ color: "gray" }}>
                    <CheckBoxRounded sx={styleCheckBox} />
                    {"Summary"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor: typeExport === 0 ? "default" : "pointer",
                      color: typeExport === 0 ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeExport === 1) {
                        handleCheckDataPdf(valueDataPDF[1].label);
                      }
                    }}
                  >
                    {valueDataPDF[1].checked ? (
                      <CheckBoxRounded sx={styleCheckBox} />
                    ) : (
                      <CheckBoxOutlineBlankRounded sx={styleCheckBoxUnCheck} />
                    )}
                    {"File Level Results Table"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor: typeData === 0 ? "default" : "pointer",

                      color: typeData === 0 ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeData === 1) {
                        handleCheckDataPdf(valueDataPDF[9].label);
                      }
                    }}
                  >
                    {valueDataPDF[9].checked ? (
                      <CheckBoxRounded
                        sx={
                          typeData === 1 ? styleCheckBox : styleCheckBoxDisable
                        }
                      />
                    ) : (
                      <CheckBoxOutlineBlankRounded
                        sx={
                          typeData === 1
                            ? styleCheckBoxUnCheck
                            : styleCheckBoxDisable
                        }
                      />
                    )}
                    {"Actions"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor:
                        typeExport === 0 || typeData === 0
                          ? "default"
                          : "pointer",
                      color:
                        typeExport === 0 || typeData === 0 ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeExport === 1 && typeData === 1) {
                        handleCheckDataPdf(valueDataPDF[2].label);
                      }
                    }}
                  >
                    {valueDataPDF[2].checked ? (
                      <CheckBoxRounded
                        sx={
                          typeExport === 1 && typeData === 1
                            ? styleCheckBox
                            : styleCheckBoxDisable
                        }
                      />
                    ) : (
                      <CheckBoxOutlineBlankRounded
                        sx={
                          typeExport === 1 && typeData === 1
                            ? styleCheckBoxUnCheck
                            : styleCheckBoxDisable
                        }
                      />
                    )}
                    {"EndPoints Details"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor:
                        typeExport === 0 || typeData === 0
                          ? "default"
                          : "pointer",
                      color:
                        typeExport === 0 || typeData === 0 ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeExport === 1 && typeData === 1) {
                        handleCheckDataPdf(valueDataPDF[3].label);
                      }
                    }}
                  >
                    {valueDataPDF[3].checked ? (
                      <CheckBoxRounded
                        sx={
                          typeExport === 1 && typeData === 1
                            ? styleCheckBox
                            : styleCheckBoxDisable
                        }
                      />
                    ) : (
                      <CheckBoxOutlineBlankRounded
                        sx={
                          typeExport === 1 && typeData === 1
                            ? styleCheckBoxUnCheck
                            : styleCheckBoxDisable
                        }
                      />
                    )}
                    {"Package Details"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor: typeExport === 0 ? "default" : "pointer",
                      color: typeExport === 0 ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeExport === 1) {
                        handleCheckDataPdf(valueDataPDF[4].label);
                      }
                    }}
                  >
                    {valueDataPDF[4].checked ? (
                      <CheckBoxRounded
                        sx={
                          typeExport === 1
                            ? styleCheckBox
                            : styleCheckBoxDisable
                        }
                      />
                    ) : (
                      <CheckBoxOutlineBlankRounded
                        sx={
                          typeExport === 1
                            ? styleCheckBoxUnCheck
                            : styleCheckBoxDisable
                        }
                      />
                    )}
                    {"List of Artifacts"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor:
                        typeExport === 0 || typeData === 0
                          ? "default"
                          : "pointer",
                      color:
                        typeExport === 0 || typeData === 0 ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeExport === 1 && typeData === 1) {
                        handleCheckDataPdf(valueDataPDF[5].label);
                      }
                    }}
                  >
                    {valueDataPDF[5].checked ? (
                      <CheckBoxRounded
                        sx={
                          typeExport === 1 && typeData === 1
                            ? styleCheckBox
                            : styleCheckBoxDisable
                        }
                      />
                    ) : (
                      <CheckBoxOutlineBlankRounded
                        sx={
                          typeExport === 1 && typeData === 1
                            ? styleCheckBoxUnCheck
                            : styleCheckBoxDisable
                        }
                      />
                    )}
                    {"Artifacts Details"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor:
                        typeExport === 0 || typeData === 0
                          ? "default"
                          : "pointer",
                      color:
                        typeExport === 0 || typeData === 0 ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeExport === 1 && typeData === 1) {
                        handleCheckDataPdf(valueDataPDF[6].label);
                      }
                    }}
                  >
                    {valueDataPDF[6].checked ? (
                      <CheckBoxRounded
                        sx={
                          typeExport === 1 && typeData === 1
                            ? styleCheckBox
                            : styleCheckBoxDisable
                        }
                      />
                    ) : (
                      <CheckBoxOutlineBlankRounded
                        sx={
                          typeExport === 1 && typeData === 1
                            ? styleCheckBoxUnCheck
                            : styleCheckBoxDisable
                        }
                      />
                    )}
                    {"Interpretation"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor:
                        typeExport === 0 || typeData === 0
                          ? "default"
                          : "pointer",
                      color:
                        typeExport === 0 || typeData === 0 ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeExport === 1 && typeData === 1) {
                        handleCheckDataPdf(valueDataPDF[7].label);
                      }
                    }}
                  >
                    {valueDataPDF[7].checked ? (
                      <CheckBoxRounded
                        sx={
                          typeExport === 1 && typeData === 1
                            ? styleCheckBox
                            : styleCheckBoxDisable
                        }
                      />
                    ) : (
                      <CheckBoxOutlineBlankRounded
                        sx={
                          typeExport === 1 && typeData === 1
                            ? styleCheckBoxUnCheck
                            : styleCheckBoxDisable
                        }
                      />
                    )}
                    {"MITRE Description"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    display={"inline-flex"}
                    sx={{
                      cursor: typeExport === 0 ? "default" : "pointer",
                      color: typeExport === 0 ? "gray" : "white",
                    }}
                    onClick={() => {
                      if (typeExport === 1) {
                        handleCheckDataPdf(valueDataPDF[8].label);
                      }
                    }}
                  >
                    {valueDataPDF[8].checked ? (
                      <CheckBoxRounded
                        sx={
                          typeExport === 1
                            ? styleCheckBox
                            : styleCheckBoxDisable
                        }
                      />
                    ) : (
                      <CheckBoxOutlineBlankRounded
                        sx={
                          typeExport === 1
                            ? styleCheckBoxUnCheck
                            : styleCheckBoxDisable
                        }
                      />
                    )}
                    {"Attack Life Cycle"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
  const screenShot = async (string) => {
    if (string === "DataListTypes") {
      document.getElementById("GraficTable").style.visibility = "visible";
    }
    document.getElementById(string).style.backgroundColor = "#FFF";
    const lifecycle = document.querySelector(`#${string}`);
    const x = await Promise.resolve(html2canvas(lifecycle));
    document.getElementById(string).style.backgroundColor = "#0a0818";
    if (string === "DataListTypes") {
      document.getElementById("GraficTable").style.visibility = "hidden";
    }
    return x;
  };
  const showDocument = (
    <Box>
      <Grid container>
        <Grid item md={12} marginBottom="30px">
          {typeExport === 0 ? (
            <Typography textAlign="center">Download Report Excel.</Typography>
          ) : (
            <Typography textAlign="center">Download Report PDF.</Typography>
          )}
        </Grid>
        <Grid item md={3}>
          <Typography variant="h6" textAlign="right">
            Type Mode:{" "}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography
            variant="h6"
            fontWeight="bold"
            textAlign="left"
            color="#3c59d48"
            style={{ marginLeft: "10px" }}
          >
            {typeMode === 0 ? " Attack" : " Defense"}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="h6" textAlign="right">
            Type Information:
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography
            style={{ marginLeft: "10px" }}
            variant="h6"
            textAlign="left"
            color="#c59d48"
          >
            {typeData === 0 ? " Summary" : " Detailed"}
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          style={{
            textAlign: "center",
            paddingTop: "15px",
            visibility: visibleLoad ? "visible" : "hidden",
          }}
        >
          <Typography>Please Wait, Building Report...</Typography>
          <div className="lds-hourglass"></div>
          <LinearProgress color="secondary" size={50} />
        </Grid>
      </Grid>
    </Box>
  );
  const body = (
    <>
      <Box className="Section" style={{ paddingLeft: "36px" }}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography>Report</Typography>
          </Grid>
          <Grid item md={12} style={styleBox}>
            {stepperMain}
          </Grid>
          <Grid item md={12} style={styleBox}>
            {activeStep === 0 ? <>{selectType}</> : null}
            {activeStep === 1 ? <>{selectMode}</> : null}
            {activeStep === 2 ? <>{selectData}</> : null}
            {activeStep === 3 ? <>{showDocument}</> : null}
          </Grid>
          <Grid item md={12}>
            <Grid container>
              <Grid item md={6}>
                <SecondaryButton
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </SecondaryButton>
              </Grid>
              <Grid item md={6} textAlign="right">
                {activeStep < 3 ? (
                  <>
                    <PrimaryButton onClick={handleNext}>Next</PrimaryButton>
                  </>
                ) : (
                  <>
                    {activeStep === 3 && typeExport === 0 ? (
                      <div
                        onClick={() => {
                          setvisibleLoad(true);
                          setTimeout(() => {
                            handleClose();
                          }, 5000);
                        }}
                      >
                        <ExporExcelMiddle
                          pageOrigin={"ReportExecution"}
                          externalData={exportExcelData}
                          fileName={fileName}
                        />
                      </div>
                    ) : (
                      <>
                        <PrimaryButton
                          disabled={visibleLoad}
                          onClick={DownloadSelect}
                        >
                          Download Report PDF
                        </PrimaryButton>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  return (
    <Box>
      <PrimaryButton onClick={handleOpen}>Reports</PrimaryButton>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </Box>
  );
}
