import {
  Box,
  Checkbox,
  FormHelperText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { Select } from "@mui/material";
import { ActionButton, SecondaryButton } from "../../../Helpers/Themes";
import { addArtifactAsync } from "../../../Services/Artifacts";
import { NotifyError } from "../../../Helpers/Scripts";
import FileUpload from "../../../Helpers/CommonComponents/FileUpload";

const regex =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

export default function AddArtefact(props) {
  const { DataUser } = props;
  const [optionsOne, setoptionsOne] = useState(false);
  const [optionsTwo, setoptionsTwo] = useState(false);
  const [MitreAttacks, setMitreAttacks] = useState([]);
  const [ArrayMitre, setArrayMitre] = useState([]);
  const [threattype, setthreattype] = useState("");
  const [threatlist, setthreatlist] = useState([]);
  const [attackDescription, setattackDescription] = useState("");
  const [urlcheck, seturlcheck] = useState(false);
  const [urlmitre, seturlmitre] = useState("");
  const [callcheck, setcallcheck] = useState(false);
  const [forcecheck, setforcecheck] = useState(false);
  const [artefactFile, setartefactFile] = useState(null);
  const [pdfFile, setpdfFile] = useState(null);
  const [xmlFile, setxmlFile] = useState(null);
  const [plataform, setplataform] = useState("all");
  const [extensions, setextensions] = useState("");
  const [ResolutionNetwork, setResolutionNetwork] = useState(null);
  const [ResolutionCall, setResolutionCall] = useState(null);
  const [ResolutionEnpoint, setResolutionEnpoint] = useState(null);
  const [ErrorField, setErrorField] = useState(false);
  const [Disablebutton, setDisablebutton] = useState(false);

  const handleThreatlist = (event) => {
    const {
      target: { value },
    } = event;
    let valores = value.indexOf(",") !== -1 ? value.split(",") : [value];
    let Descript = "";
    let arrayMitre = [];
    for (let index = 0; index < valores[0].length; index++) {
      for (let j = 0; j < DataUser.List.length; j++) {
        if (valores[0][index] === DataUser.List[j].id) {
          arrayMitre.push(DataUser.List[j]["id"]);
          if (Descript === "") {
            Descript = DataUser.List[j]["description"];
          } else {
            Descript += "," + DataUser.List[j]["description"];
          }
        }
      }
    }
    setArrayMitre(arrayMitre);
    setattackDescription(Descript);
    setMitreAttacks(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const GetCatLevel = useCallback(async () => {
    const response = JSON.parse(localStorage.getItem("threatTypes"));
    if (response) {
      let array = [];
      response.forEach((element) => {
        array.push({
          id: element._id,
          name: element.name === "Midd" ? "Mid" : element.name,
        });
      });
      setthreatlist(array);
    }
  }, []);
  useEffect(() => {
    GetCatLevel();
  }, [GetCatLevel]);

  const selectPlataform = (e) => {
    let extension = e.target.value === "Windows" ? ".exe" : "";
    setplataform(e.target.value);
    setextensions(extension);
  };
  const selectThreattype = (e) => {
    setthreattype(e.target.value);
  };
  const handlePdfFile = (e) => {
    if (e.files[0]) {
      setpdfFile(e.files[0]);
    }
  };
  const handleArtefactfile = (e) => {
    if (e.files[0]) {
      setartefactFile(e.files[0]);
    }
  };
  const handleXml = (e) => {
    if (e.files[0]) {
      setxmlFile(e.files[0]);
    }
  };
  const handleUrl = (e) => {
    if (regex.exec(e.target.value)) {
      seturlmitre(e.target.value);
    } else {
      seturlmitre("");
    }
  };

  const handleDescription = (e) => {
    setattackDescription(e.target.value);
  };
  const handleResolutionEndpoint = (e) => {
    setResolutionEnpoint(e.target.value);
  };
  const handleResolutionCall = (e) => {
    setResolutionCall(e.target.value);
  };
  const handleResolutionNetwork = (e) => {
    setResolutionNetwork(e.target.value);
  };
  const uploadFile = async () => {
    if (
      artefactFile &&
      MitreAttacks.length > 0 &&
      threattype !== "" &&
      attackDescription !== ""
    ) {
      if (urlcheck && urlmitre === "") {
        setErrorField(true);
        NotifyError("Please enter url field *");
      } else {
        if (forcecheck && xmlFile === null) {
          setErrorField(true);
          NotifyError("Select xml file");
        } else {
          setDisablebutton(true);
          const artifactData = {
            file: artefactFile,
            fk_threatType: threattype,
            isCallback: callcheck ? "1" : "0",
            isEvasion: forcecheck ? "1" : "0",
            description: attackDescription,
            phaseAttacks: ArrayMitre,
            manifest: xmlFile,
            platform: plataform,
            resolutionNetwork: ResolutionNetwork,
            resolutionEndPoint: ResolutionEnpoint,
            resolutionCallback: ResolutionCall,
            urlMitre: urlmitre,
            anexo: pdfFile,
          };
          const response = await addArtifactAsync(artifactData);
          setDisablebutton(false);
          if (response === true) {
            props.Reload();
          }
        }
      }
    } else {
      setErrorField(true);
      setDisablebutton(false);
      NotifyError("Please enter all fields *");
    }
  };
  const checkButton = () => {
    if (
      artefactFile &&
      MitreAttacks.length > 0 &&
      threattype !== "" &&
      attackDescription !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <Box className="AddArtifact">
      <Box className="Header">
        <Typography className="Title">Add artifact</Typography>
      </Box>
      <Box className="Body" style={{ height: "auto" }}>
        <Box className="SelectSystemContent">
          <Typography className="TexTTitles">Platform</Typography>
          <Select
            sx={{
              "& .MuiSvgIcon-root": {
                color: "white",
              },
            }}
            className="SelectInput"
            label="Threat"
            value={plataform}
            onChange={(e) => {
              selectPlataform(e);
            }}
          >
            <MenuItem disabled className="SelectItem"></MenuItem>
            <MenuItem
              className="SelectItem"
              value="Windows"
              style={{
                backgroundColor:
                  plataform === "Windows" ? "#c59d4852" : "transparent",
              }}
            >
              Windows
            </MenuItem>
            <MenuItem
              className="SelectItem"
              value="GNULinux"
              style={{
                backgroundColor:
                  plataform === "GNULinux" ? "#c59d4852" : "transparent",
              }}
            >
              GNU/Linux
            </MenuItem>
          </Select>
        </Box>
        <Box className="InputContent">
          <Typography className="TexTTitles">
            {extensions !== "" ? "*Ext. Allowed .exe" : extensions}
          </Typography>
          <Box className="ContainerOne">
            <Box className="ContainerTwo">
              <FileUpload
                NameID="myfile"
                HandleFile={handleArtefactfile}
                ArtifactType={true}
                Error={!artefactFile && ErrorField}
              />
            </Box>
          </Box>
        </Box>
        <Box className="InputContent">
          <Typography className="TexTTitles">Artifact Report (PDF)</Typography>
          <Box className="ContainerOne">
            <Box className="ContainerTwo">
              <FileUpload NameID="mypdf" HandleFile={handlePdfFile} />
            </Box>
          </Box>
        </Box>
        <Box className="SelectContent">
          <Typography className="TexTTitles">*Severity</Typography>
          <Select
            sx={{
              "& .MuiSvgIcon-root": {
                color: "white",
              },
            }}
            className="SelectInput"
            label="Threat"
            onChange={(e) => {
              selectThreattype(e);
            }}
            value={threattype}
            error={threattype === "" && ErrorField}
          >
            <MenuItem className="SelectItem" value="" disabled>
              Challenge Level
            </MenuItem>
            {threatlist.map((option) => (
              <MenuItem
                value={option.id}
                className="SelectItem"
                style={{
                  backgroundColor:
                    threattype === option.id ? "#c59d4852" : "transparent",
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="SelectContent">
          <Typography className="TexTTitles">*MITRE ATT&CK Tactics</Typography>
          <Select
            sx={{
              "& .MuiSvgIcon-root": {
                color: "white",
              },
            }}
            className="SelectInput"
            multiple
            value={MitreAttacks}
            onChange={handleThreatlist}
            error={MitreAttacks.length === 0 && ErrorField}
          >
            <MenuItem className="SelectItem" value="" disabled>
              MITRE ATT&CK Tactics
            </MenuItem>
            {DataUser.List.map((option) => (
              <MenuItem
                className="SelectItem"
                value={option.id}
                style={{
                  backgroundColor:
                    ArrayMitre.indexOf(option.id) !== -1
                      ? "#c59d4852"
                      : "transparent",
                }}
              >
                <Checkbox
                  checked={ArrayMitre.indexOf(option.id) !== -1}
                  style={{
                    color: "#c59d48",
                  }}
                ></Checkbox>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="TextAreaContent">
          <Typography className="TexTTitles">Description</Typography>
          <textarea
            multiline
            fullWidth
            className="TextArea"
            value={attackDescription}
            onChange={(e) => {
              handleDescription(e);
            }}
          >
            {attackDescription}
          </textarea>
        </Box>

        <Box className="ShowButtonContent">
          <Box className="ShowBox">
            <Box className="BoxButton">
              <SecondaryButton
                onClick={() => {
                  setoptionsOne(!optionsOne);
                }}
              >
                {!optionsOne ? "Show options" : "Hide Options"}
              </SecondaryButton>
            </Box>
            <Box
              className="CollapseContent"
              style={{ display: optionsOne ? "block" : "none" }}
            >
              <Box className="OptionCollapse">
                <Box className="OptionBlock">
                  <Checkbox
                    style={{
                      marginTop: "7px",
                      color: "#c59d48",
                    }}
                    value={urlcheck}
                    onClick={() => {
                      seturlcheck(!urlcheck);
                    }}
                  />
                  <Typography
                    style={{ marginTop: "0px" }}
                    className="CheckBlock"
                  >
                    ATT&CK Navigator URL
                  </Typography>
                </Box>
                <Box
                  className="InputBlock"
                  style={{ display: urlcheck ? "block" : "none" }}
                >
                  <TextField
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    label="Enter Url"
                    id="myUrl"
                    style={{ color: "#fff" }}
                    sx={{
                      color: "white",
                      "& .MuiInputLabel-root": {
                        color: "#FFF",
                      },
                      "& .MuiOutlinedInput-root": {
                        color: "#FFF",
                        "& fieldset": {
                          borderColor: "#464366",
                        },
                        "&:hover fieldset": {
                          borderColor: "#c59d48",
                        },

                        "& .MuiSvgIcon-root": {
                          color: "#FFF !important",
                        },
                      },
                      ".MuiOutlinedInput-root": {
                        borderRadius: "10px",
                      },
                    }}
                    onChange={(e) => {
                      handleUrl(e);
                    }}
                    error={urlcheck && urlmitre === "" && ErrorField}
                  />

                  <FormHelperText style={{ color: "#aeb9ca80" }}>
                    {urlmitre === ""
                      ? "exemple http://reveald.com"
                      : "Url valid"}
                  </FormHelperText>
                </Box>

                <Box className="OptionBlock">
                  <Checkbox
                    style={{
                      marginTop: "7px",
                      color: "#c59d48",
                    }}
                    value={callcheck}
                    color="secondary"
                    onClick={() => {
                      setcallcheck(!callcheck);
                    }}
                  />
                  <Typography className="CheckBlock">Callback</Typography>
                </Box>
                <Box className="OptionBlock">
                  <Checkbox
                    style={{
                      marginTop: "7px",
                      color: "#c59d48",
                    }}
                    value={forcecheck}
                    color="secondary"
                    onClick={() => {
                      setforcecheck(!forcecheck);
                    }}
                  />
                  <Typography className="CheckBlock">
                    Force Network CyberSecurity
                  </Typography>
                </Box>
                <Box
                  className="InputBlock"
                  style={{ display: forcecheck ? "block" : "none" }}
                >
                  <Box className="ContainerOne">
                    <Box className="ContainerTwo">
                      <FileUpload
                        NameID="myXml"
                        HandleFile={handleXml}
                        Validator={"xml"}
                        Error={forcecheck && xmlFile === null && ErrorField}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="ShowBox">
            <Box className="BoxButton">
              <SecondaryButton
                onClick={() => {
                  setoptionsTwo(!optionsTwo);
                }}
              >
                {!optionsTwo
                  ? "Show Resolution Actions"
                  : "Hide Resolutions Actions"}
              </SecondaryButton>
            </Box>
            <Box
              className="CollapseContent"
              style={{ display: optionsTwo ? "block" : "none" }}
            >
              <Box className="TextAreaContent">
                <Typography className="TexTTitles">
                  Network Resolution Actions
                </Typography>
                <textarea
                  multiline
                  fullWidth
                  className="TextArea"
                  value={ResolutionNetwork}
                  onChange={(e) => {
                    handleResolutionNetwork(e);
                  }}
                ></textarea>
              </Box>
              <Box className="TextAreaContent">
                <Typography className="TexTTitles">
                  Endpoint Resolution Actions
                </Typography>
                <textarea
                  multiline
                  fullWidth
                  className="TextArea"
                  value={ResolutionEnpoint}
                  onChange={(e) => {
                    handleResolutionEndpoint(e);
                  }}
                ></textarea>
              </Box>
              <Box className="TextAreaContent">
                <Typography className="TexTTitles">
                  Callback Resolution Actions
                </Typography>
                <textarea
                  multiline
                  fullWidth
                  className="TextArea"
                  value={ResolutionCall}
                  onChange={(e) => {
                    handleResolutionCall(e);
                  }}
                ></textarea>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="Footer">
          <Box className="ButtonSection">
            <ActionButton
              onClick={uploadFile}
              disabled={checkButton() || Disablebutton}
            >
              {Disablebutton ? "In process" : "Save"}
            </ActionButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
