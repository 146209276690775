import { useEffect, useMemo, useState } from "react";
import Cookies from "universal-cookie";
import Typography from "@mui/material/Typography";
import {
  Box,
  ThemeProvider,
  IconButton,
  Chip,
  Menu,
  // Tooltip,
  styled,
  Button,
  Grid,
  Modal,
  TablePagination,
} from "@mui/material";
import launchIcon from "../../assets/images/launch-icon.svg";
import {
  QuickSearchToolbar,
  pageTheme,
  StyledDataGrid,
  CustomLoadingOverlay,
  ActionButton,
  SecondaryButton,
} from "../../Helpers/Themes";
import { Delete, MoreVert, Upcoming } from "@mui/icons-material";
import ModalAdvanceInfo from "./AdvanceInfo/AdvanceInfo";
import ModalCreate from "./ModalCreate/ModalCreate";
import { deleteIOC } from "../../Services/IOCExecution";
import { ConvertDateUtc } from "../../Helpers/GlobalData";
import { GridOverlay } from "@mui/x-data-grid-pro";
import GeneralModalDelete from "../../Helpers/CommonComponents/GeneralDeleteModal";
import axios from "axios";
import { env2 } from "../../Helpers/Instance";
import { endPoint } from "../../Services/EndPoint";
import AxiosFetch from "../../Services/FetchServices";

const ButtonLauncher = styled(Button)({
  padding: "10px",
  color: "#fff",
  backgroundColor: "#b69141",
});

export default function IOCExecutions(props) {
  const { Reload, ReloadClientIOC } = props;
  const [ReloadPage, setReloadPage] = useState(Reload);
  const [rows, setRows] = useState([]);
  const [totalRows, settotalRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //const [ExportData, setExportData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [count, setCount] = useState(0);
  const [sortModel, setSortModel] = useState([
    { field: "updatedAt", sort: "desc" },
  ]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [IDexecute, setIDexecute] = useState("");
  const [page, setPage] = useState(0);
  const [signalReload, setsignalReload] = useState(0);
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array

  useEffect(() => {
    cookies.set("CurrentPageDataPagination", "16", {
      path: "/",
      SameSite: "none",
      secure: true,
    });
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const url = env2.api + endPoint.allIOC;
    async function GetData() {
      const data = await AxiosFetch("GET", url, source);
      const response = data?.msg ? data.msg : null;
      if (response?.result && response.query) {
        let array = [];
        response.result.forEach((element) => {
          array.push({
            id: element._id,
            name: element.name,
            tasks: element.tasks,
            client: element.lastName,
            createdBy: element?.createdBy ? element.createdBy.userName : "N/A",
            updatedAt: ConvertDateUtc(element.updatedAt),
            tagStatus: element.isTest,
          });
        });
        setRows(array);
        settotalRows(array);
        let NoTotal = array.length;
        setCount(NoTotal);
      } else {
        setRows([]);
        settotalRows([]);
        setCount(0);
      }
    }
    GetData();
    if (Reload !== 0) setReloadPage(Reload);
    return () => {
      source.cancel();
    };
  }, [cookies, Reload, ReloadPage, ReloadClientIOC, signalReload]);

  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };
  const handleOpen = (id) => {
    setIDexecute(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setIDexecute(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    let filteredRows = [];
    totalRows?.forEach((row) => {
      if (row.name.toUpperCase().indexOf(searchValue.toUpperCase()) > -1) {
        filteredRows.push(row);
      }
    });
    setRows(filteredRows);
    let NoTotal = filteredRows.length;
    setCount(NoTotal);
    setPage(0);
    if (searchValue === "") {
      setRows(totalRows);
      let NoTotal = totalRows.length;
      setCount(NoTotal);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "updatedAt",
      headerName: "updatedAt",
      flex: 1,
      minWidth: 100,

      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          Last modification
        </Box>
      ),
    },
    {
      field: "tagStatus",
      headerName: "Status",
      type: "singleSelect",
      flex: 0.5,
      minWidth: 150,

      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Status</Box>
      ),
      renderCell: (params) => (
        <Chip
          style={{ width: "120px" }}
          variant="outlined"
          label={params.value}
          color={params.value === "tested" ? "primary" : "error"}
        />
      ),
    },
    {
      field: "Launch",
      headerName: "Launch",
      flex: 0.5,
      minWidth: 150,
      align: "center",
      sortable: false,
      filterable: false,
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Launch</Box>
      ),
      renderCell: (params) => (
        <>
          {/* <Tooltip title="Launch IoC Validation" arrow> */}
          <ButtonLauncher onClick={handleOpen.bind(this, params.row.id)}>
            <img style={{ marginRight: "8px" }} src={launchIcon} alt="" />
            Launch
          </ButtonLauncher>
          {/* </Tooltip> */}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 0.5,
      minWidth: 150,

      sortable: false,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Actions</Box>
      ),
      renderCell: (params) => (
        <>
          <ModalAdvanceInfo ExecutionInfo={params.row} />
          {/* <Tooltip title="Delete"> */}
          <IconButton
            className="iconButtonSize"
            onClick={handleOpenDelete.bind(this, params.row.id)}
          >
            <Delete />
          </IconButton>
          {/* </Tooltip> */}
        </>
      ),
    },
  ];

  const DelIOC = async () => {
    const response = await deleteIOC(IDexecute);
    if (response) {
      setOpenDelete(false);
      setsignalReload(signalReload + 1);
      setPage(0);
    }
  };

  const ExecuteIOC = () => {
    let Request = {
      IOC: IDexecute,
    };
    props.Execute(Request);
    handleClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileId = "page-menu-mobile";
  const renderMobileMenu = (
    <Box>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <ModalCreate
          Reload={() => {
            setsignalReload(signalReload + 1);
          }}
        />
      </Menu>
    </Box>
  );

  return (
    <Box style={{ width: "100%" }}>
      <Modal open={open} onClose={handleClose}>
        <Box className="contenido" style={{ maxWidth: "500px" }}>
          <Box className="header">
            <Typography className="headerTitle" style={{ textAlign: "center" }}>
              Launch
            </Typography>
            <Typography
              className="comn-label-dash"
              style={{ textAlign: "center" }}
            >
              Do you want to launch this IoC Validation?
            </Typography>
          </Box>

          <Grid container>
            <Grid item md={12}>
              <Box style={{ textAlign: "center" }}>
                <ActionButton onClick={ExecuteIOC.bind()}>Launch</ActionButton>
                <SecondaryButton onClick={handleClose.bind()}>
                  Cancel
                </SecondaryButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <GeneralModalDelete
        ExternalOpen={openDelete}
        HeaderText=""
        BodyText="Do you want to Delete this IoC Validation?"
        CloseAction={handleCloseDelete}
        PrimaryAction={DelIOC}
        CancelAction={handleCloseDelete}
      />
      <ThemeProvider theme={pageTheme}>
        <Typography variant="pageTitle">
          <Upcoming className="rt-icon-header" style={{ color: "#c59d48" }} />
          IoC Validation
          <Box
            sx={{
              float: { xs: "none", md: "right" },
              display: { xs: "none", md: "flex" },
            }}
          >
            <ModalCreate
              ReloadPage={ReloadPage}
              Reload={() => {
                setsignalReload(signalReload + 1);
              }}
            />
          </Box>
          <Box
            sx={{
              float: { xs: "right", md: "none" },
              display: { xs: "inline-block", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVert />
            </IconButton>
          </Box>
        </Typography>
        {renderMobileMenu}
        <Box
          style={{
            width: "100%",
            backgroundColor: "#242933",
            border: " 1px solid #090f1a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "14px",
            marginTop: "24px",
            height: "auto",
          }}
        >
          <div style={{ height: "auto", width: "100%" }}>
            <StyledDataGrid
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                NoResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Results Filter Data</div>
                  </GridOverlay>
                ),
                Pagination: () => (
                  <>
                    <TablePagination
                      component="div"
                      page={page}
                      count={count}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={[5, 10, 20]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton
                      showLastButton
                    />
                  </>
                ),
              }}
              rows={rows}
              columns={columns}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={[5, 10, 20]}
              rowCount={count}
              page={page}
              onPageChange={handleChangePage}
              sortModel={sortModel}
              onSortModelChange={(newSort) => handleSortModelChange(newSort)}
              onPageSizeChange={(PerPage) => setRowsPerPage(PerPage)}
              disableSelectionOnClick
              density="comfortable"
              autoHeight
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
          </div>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
