import { useEffect, useState } from "react";
import { GetDataClientAll } from "../Pages/Agents/Common/Models/ModelClientDataAgent";
import { getClientsAsync } from "../Services/Clients";
import { GetDate } from "./Scripts";
import ExcelIcon from "../assets/images/excel-icon.svg";
import { DownloadOutlined } from "@mui/icons-material";
import "./ExportExcel.scss";
import ExporExcel from "./ExportExcel";
import { getAllPackagesAsync } from "../Services/Package";
import { GetExecutionsAsymc } from "../Services/Executions";
import { myColor } from "../BaseCss";
const styleButton = {
  margin: "8px",
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "10px 30px",
  borderRadius: "12px",
  border: "1px solid gray",
  lineHeight: 1.5,
  justifyContent: "center",
  display: "flex",
  backgroundColor: myColor.SecondaryButton,
  color: "#fff",
  cursor:"pointer",
  "&:hover": {
    backgroundColor: "#544628",
    borderColor: "#1C1A33",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "090f1a",
    borderColor: "#1C1A33",
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "#1C1A33",
  },
};
export default function ExporExcelMiddle(props) {
  const { pageOrigin, fileName, externalData = [] } = props;
  const [data, setData] = useState(null);
  const [process, setProcess] = useState(false);

  const AgentsExcel = async () => {
    let dataAllAgents;
    let response = await getClientsAsync();
    if (response) {
      dataAllAgents = GetDataClientAll(response);
    }
    let expo = {
      data: dataAllAgents,
      colums: [
        { label: "Host name", value: "hostname" },
        { label: "Private IP address", value: "private" },
        { label: "Public IP address ", value: "ip" },
        { label: "Port", value: "port" },
      ],
    };

    setData(expo);
  };
  const AgentHistoryExcel = () => {
    let dataxlsx = [];
    externalData?.forEach((element) => {
      dataxlsx.push({
        Execution: element.Execution,
        Package: element.Package,
        Success: element.Success,
        Fail: element.Fail,
        Date: GetDate(element.Date),
      });
    });
    let expo = {
      data: dataxlsx,
      colums: [
        { label: "Emulation", value: "Execution" },
        { label: "Package", value: "Package" },
        { label: "Success", value: "Success" },
        { label: "Fail", value: "Fail" },
        { label: "Date", value: "Date" },
      ],
    };

    setData(expo);
  };
  const PackageExcel = async () => {
    let response = await getAllPackagesAsync();
    if (response) {
      response.forEach((packagedetails) => {
        packagedetails.createdAt = GetDate(packagedetails.createdAt);
        packagedetails.updatedAt = GetDate(packagedetails.updatedAt);
      });
    }
    let expo = {
      data: response,
      colums: [
        { label: "Name", value: "name" },
        { label: "Created", value: "createdAt" },
        { label: "Updated", value: "updatedAt" },
        { label: "Type", value: "type" },
      ],
    };

    setData(expo);
  };

  const ExecutionExcel = async () => {
    const response = await GetExecutionsAsymc();
    let array = [];
    response?.forEach((element) => {
      array.push({
        name: element.name,
        lastModification: GetDate(element.lastModification),
        status: element.status,
        vector:
          element.vector === "RealExecution"
            ? "Execution"
            : element.vector === "EPP"
            ? "Endpoint"
            : "Network",
      });
    });
    let expo = {
      data: array,
      colums: [
        { label: "Name", value: "name" },
        { label: "Last Modification", value: "lastModification" },
        { label: "Status", value: "status" },
        { label: "Vector", value: "vector" },
      ],
    };

    setData(expo);
  };
  const ReportExcel = async () => {
    setData(externalData);
  };

  const handleClick = async () => {
    setProcess(true);

    switch (pageOrigin) {
      case "Agents":
        AgentsExcel();
        break;
      case "AgentsHistory":
        AgentHistoryExcel();
        break;
      case "Packages":
        PackageExcel();
        break;
      case "Execution":
        ExecutionExcel();
        break;
      case "ReportExecution":
        ReportExcel();
        break;
      case "ScriptReport":
        ReportExcel();
        break;
      case "AgentDiagram":
        ReportExcel();
        break;
      case "Groups":
        ReportExcel();
        break;
      case "Users":
        ReportExcel();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        let button = document.getElementById("exportedButton");
        if (button) {
          button.click();
        }
        setProcess(false);
        setData(null);
      }, 3000);
    }
  }, [data]);

  return (
    <>
      <div
        disabled={pageOrigin === "none"}
        variant="outlined"
        id="exportedButtonAction"
        style={styleButton}
        onClick={handleClick}
      >
        {!process ? (
          <img
            src={ExcelIcon}
            alt=""
            style={{ width: "20px", marginRight: "7px" }}
          />
        ) : (
          <DownloadOutlined className="iconAnimated" />
        )}
        {!process ? (
          "Get Data Report"
        ) : (
          <label className="textAnimated">Wait at moment ...</label>
        )}
      </div>
      <div
        style={{
          width: "0px",
          height: "0px",
          backgroundColor: "transparent",
          borderColor: "transparent",
        }}
      >
        <ExporExcel importData={data} fileName={fileName} />
      </div>
    </>
  );
}
ExporExcelMiddle.defaultProps = {
  pageOrigin: "none",
  fileName: "Reveald Data Export",
  floatStyle: "none",
};
