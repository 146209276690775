import { endPoint } from "./EndPoint";
import { env2 } from "../Helpers/Instance";
import { getMsgResult, getResultAndShowSuccess } from "./ResponseValidation";
import AxiosFetch from "./FetchServices";

const EXECUTION_UPDATED = "Emulation updated successfully";
const EXECUTION_DELETED = "Emulation deleted successfully";
// const EXECUTION_CREATED = "Execution created successfully";

export async function GetExecutionsAsymc() {
  const url = env2.api + endPoint.executions;
  const response = await AxiosFetch("GET", url);
  const result = getMsgResult(response);
  return result;
}

export async function AddExecutionAsync(data) {
  const url = env2.api + endPoint.addExecution;
  const body = JSON.stringify(data);
  const response = await AxiosFetch("POST", url, null, body);
  const result = getMsgResult(response);
  return result;
}

export async function UpdateExecutionAsync(data) {
  const url = env2.api + endPoint.updateExecution + data.id;
  const body = JSON.stringify(data);
  const response = await AxiosFetch("POST", url, null, body);
  const result = getResultAndShowSuccess(response, EXECUTION_UPDATED);
  return result;
}

export async function DesactivateExecutionAsync(id) {
  const url = env2.api + endPoint.desactivateExecution + id;
  const response = await AxiosFetch("GET", url);
  const result = getResultAndShowSuccess(response, EXECUTION_DELETED);
  return result;
}
