import { endPoint } from "./EndPoint";
import { env2 } from "../Helpers/Instance";
import { getMsg } from "./ResponseValidation";
import AxiosFetch from "./FetchServices";

export async function getReportByExecutionIdAsync(executionId, body) {
  const url = env2.api + endPoint.getListExecutions + executionId;
  const response = await AxiosFetch("POST", url, null, body);
  return getMsg(response);
}

export async function getReportData(executionId, body = []) {
  const url = env2.api + endPoint.getInfoExecution + executionId;
  const response = await AxiosFetch("POST", url, null, body);
  return getMsg(response);
}

export async function GetClientReportAsync(id) {
  const url = env2.api + endPoint.clientReport + id + "/page/10/1";
  const response = await AxiosFetch("GET", url);
  return getMsg(response);
}

export async function getDataReportCustomHistory(executionId, body) {
  const url = env2.api + endPoint.reportDeatiled + executionId;
  const response = await AxiosFetch("POST", url, null, JSON.stringify(body));
  return getMsg(response);
}
