import { endPoint } from "./EndPoint";
import { env2 } from "../Helpers/Instance";
import { downloadFile } from "./FetchRequests";
import {
  getResultAndShowSuccess,
  getMsgResult,
  getResult,
} from "./ResponseValidation";
import AxiosFetch from "./FetchServices";

const PACKAGE_DELETED = "Package deleted successfully";

export async function desactivatePackageById(id) {
  const url = env2.api + endPoint.desactivatePackageById + id;
  const response = await AxiosFetch("GET", url);
  return getResultAndShowSuccess(response, PACKAGE_DELETED);
}

export async function getAllPackagesAsync() {
  const url = env2.api + endPoint.packages;
  const response = await AxiosFetch("GET", url);
  return getMsgResult(response);
}

export async function getPackageByIdAsync(packageId) {
  const url = env2.api + endPoint.package + packageId;
  const response = await AxiosFetch("GET", url);
  return getMsgResult(response);
}

export async function postPackageAsync(packageData) {
  const url = env2.api + endPoint.addPackage;
  let formdata = new FormData();
  formdata.append("name", packageData.name);
  formdata.append("description", packageData.description);
  formdata.append("type", packageData.type);
  packageData.fk_artifacts.forEach((element) => {
    formdata.append("fk_artifacts", element);
  });

  let response;
  if (packageData?.anexo) {
    formdata.append("anexo", packageData.anexo);
  }

  response = await AxiosFetch("POST", url, null, formdata);
  const resultOk = getResult(response);
  return resultOk;
}

export async function getAnexoPackage(token, packageId, packageName) {
  const url = env2.api + endPoint.downloadAnexoPackage + packageId;
  await downloadFile(token, url, packageName);
}

export async function getPackagePageMinAsync(filterData) {
  const url =
    env2.api +
    endPoint.packagePageMin +
    filterData.pageSize +
    "/" +
    filterData.pageNumber +
    "?col=" +
    filterData.filter +
    "&order=" +
    filterData.order;
  let body = new FormData();

  if (filterData.filterName !== undefined && filterData.filterName !== "") {
    body = JSON.stringify({ name: filterData.filterName });
  }

  const response = await AxiosFetch("POST", url, null, body);
  const result = getMsgResult(response);
  return result;
}

export async function updatePackageAsync(id, packageData) {
  const url = env2.api + endPoint.changePackage + id;
  let formdata = new FormData();
  formdata.append("name", packageData.name);
  formdata.append("description", packageData.description);
  formdata.append("type", packageData.type);
  packageData.fk_artifacts.forEach((element) => {
    formdata.append("fk_artifacts", element);
  });

  if (packageData?.anexo !== null && packageData?.anexo !== "") {
    formdata.append("anexo", packageData.anexo);
  } else {
    formdata.append("anexo", "CLSFILE");
  }

  const response = await AxiosFetch("PUT", url, null, formdata);
  const result = getResult(response);
  return result;
}

export async function getPackagePageAdvancedAsync(
  size,
  Nopage,
  orderby,
  order,
  bodyContent
) {
  const url =
    env2.api +
    endPoint.packagePageAdvanced +
    size +
    "/" +
    Nopage +
    "?col=" +
    orderby +
    "&order=" +
    order;

  let ArrayBody = {};
  if (bodyContent.name) {
    ArrayBody.name = bodyContent.name;
  }
  if (bodyContent.Type) {
    ArrayBody.type = bodyContent.Type;
  }
  if (bodyContent.Status) {
    ArrayBody.tagTest = bodyContent.Status;
  }
  const body = JSON.stringify(ArrayBody);
  const response = await AxiosFetch("POST", url, null, body);
  const result = getMsgResult(response);
  return result;
}

export async function GetPackageSize() {
  const url = env2.api + endPoint.packageSize;
  const response = await AxiosFetch("GET", url);
  return getMsgResult(response);
}
