import { Box, Input, MenuItem, Typography } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import toastr from "toastr";
import { Select } from "@mui/material";
import { ActionButton, FormInputText } from "../../../Helpers/Themes";
import { postPackageAsync } from "../../../Services/Package";
import FileUpload from "../../../Helpers/CommonComponents/FileUpload";

export default function CreatePackage(props) {
  const { FileList } = props;
  const [typesPack, settypesPak] = useState("");
  const [list, setlist] = useState([]);
  const [Description, setDescription] = useState("");
  const [PackageName, setPackageName] = useState("");
  const [ErrorField, setErrorField] = useState(false);
  const [pdfFile, setpdfFile] = useState(null);
  const [FileListData, setFileListData] = useState(FileList);
  const [disabledButton, setdisabledButton] = useState(false);
  useEffect(() => {
    setFileListData(FileList);
  }, [FileList]);
  const GetCatTypes = useCallback(async () => {
    const response = JSON.parse(localStorage.getItem("packageTypes"));
    if (response) {
      let array = [];
      response.forEach((element) => {
        array.push({
          id: element._id,
          name: element.name,
        });
      });
      setlist(array);
    }
  }, []);
  useEffect(() => {
    GetCatTypes();
  }, [GetCatTypes]);

  const selectType = (e) => {
    settypesPak(e.target.value);
  };
  const handleDescription = (e) => {
    if (e.target.value !== " ") {
      setDescription(e.target.value);
    } else {
      setDescription("");
    }
  };

  const handleName = (e) => {
    setPackageName(e.target.value);
  };
  const CreatePackageFunction = async () => {
    setdisabledButton(true);
    if (PackageName !== "" && typesPack !== "" && Description !== "") {
      let packageData = {
        name: PackageName,
        description: Description,
        type: typesPack,
        fk_artifacts: FileListData.map((element) => {
          return element.id;
        }),
        anexo: pdfFile,
      };
      const response = await postPackageAsync(packageData);
      if (response === true) {
        toastr.success("Package Create Successful");
        props.Reload();
        setdisabledButton(false);
      } else {
        toastr.error("Error Create Package");
        setdisabledButton(false);
      }
    } else {
      setErrorField(true);
      toastr.error("Please enter all fields *");
      setdisabledButton(false);
    }
  };
  const handlePdfFile = (e) => {
    if (e.files[0]) {
      setpdfFile(e.files[0]);
    }
  };

  return (
    <Box className="AddArtifact">
      <Box className="Header">
        <Typography className="Title">New package</Typography>
      </Box>

      <Box className="Body">
        <Typography className="TexTTitles">*Artifacts selected:</Typography>
        <Box style={{ maxHeight: "200px", overflowY: "auto" }}>
          {FileListData.map((element, i) => {
            return (
              <Typography className="TexTTitles">
                {i + 1 + ".- " + element.name}{" "}
              </Typography>
            );
          })}
        </Box>

        <Box className="InputContent">
          <Typography className="TexTTitles">*Package name</Typography>
          <Box className="ContainerOnePackage">
            <FormInputText
              style={{ color: "#fff", borderColor: "transparent" }}
              fullWidth
              onChange={(e) => {
                handleName(e);
              }}
              error={PackageName === "" && ErrorField}
            ></FormInputText>
          </Box>
        </Box>
        <Box className="SelectContent">
          <Typography className="TexTTitles">*Choose a package type</Typography>
          <Select
            sx={{
              "& .MuiSvgIcon-root": {
                color: "white",
              },
            }}
            className="SelectInput"
            value={typesPack}
            onChange={selectType}
            error={typesPack === "" && ErrorField}
          >
            <MenuItem className="SelectItem" value="" disabled>
              Package type
            </MenuItem>
            {list.map((option) => (
              <MenuItem className="SelectItem" value={option.id}>
                {option.name === "Interactiva" ? "Interactive" : option.name}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box className="TextAreaContent">
          <Typography className="TexTTitles">*Description</Typography>
          <Input
            rows="8"
            multiline
            maxRows={8}
            fullWidth
            className="TextArea"
            value={Description}
            onChange={handleDescription}
            error={Description === "" && ErrorField}
          >
            {Description}
          </Input>
        </Box>
        <Box className="TextAreaContent">
          <Typography className="TexTTitles">Report (PDF)</Typography>
          <Box className="ContainerOne">
            <Box className="ContainerTwo">
              <FileUpload NameID="mypdf" HandleFile={handlePdfFile} />
            </Box>
          </Box>
        </Box>
        <Box className="Footer">
          <Box className="ButtonSection">
            <ActionButton
              disabled={disabledButton}
              onClick={() => {
                setdisabledButton(true);
                CreatePackageFunction();
              }}
            >
              Save
            </ActionButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
