import { env2 } from "../Helpers/Instance";
import { endPoint } from "./EndPoint";
import AxiosFetch from "./FetchServices";
import { getMsgResult, getResultAndShowSuccess } from "./ResponseValidation";

const CLIENT_DELETED = "Client deleted successfully";

export async function getClientsAsync() {
  const url = env2.api + endPoint.clients;
  const response = await AxiosFetch("GET", url);
  return getMsgResult(response);
}

export async function getOnlineClientsAsync() {
  const url = env2.api + endPoint.onlineClients;
  
  const response = await AxiosFetch("GET", url);
  
  return getMsgResult(response);
}

export async function deleteClient(clientId) {
  const url = env2.api + endPoint.deleteClient + clientId;
  const response = await AxiosFetch("DELETE", url);
  return getResultAndShowSuccess(response, CLIENT_DELETED);
}
export async function changeClient( clientId, body) {
  const url = env2.api + endPoint.changeGroup + clientId;
  const response = await AxiosFetch("POST", url, null, body);
  return getMsgResult(response);
}

export async function changeAliasClient(clientId, body) {
  const url = env2.api + endPoint.updateAlias + clientId;
  const response = await AxiosFetch("POST", url, null, body);
  return getMsgResult(response);
}
