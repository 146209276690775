import Newlmodal from "./components/NewUser/NewUser";
import Changemodal from "./components/ChangeUser/ChangeUser";
import DetailmodalUser from "./components/DetailUser/DetailUser";
import Deletemodal from "./components/DeleteUser/DeleteUser";
import { useEffect, useMemo, useState } from "react";
import Cookies from "universal-cookie";
import Typography from "@mui/material/Typography";
import {
  Box,
  ThemeProvider,
  IconButton,
  Chip,
  Menu,
  TablePagination,
} from "@mui/material";
import Icon from "../../assets/images/users-icon-svg.svg";
import {
  QuickSearchToolbar,
  pageTheme,
  StyledDataGrid,
  CustomLoadingOverlay,
  escapeRegExp,
} from "../../Helpers/Themes";
import { MoreVert } from "@mui/icons-material";
import { ImageArray } from "./UserServices";
import { GridOverlay } from "@mui/x-data-grid-pro";
import AxiosFetch from "../../Services/FetchServices";
import { endPoint } from "../../Services/EndPoint";
import { env2 } from "../../Helpers/Instance";
import axios from "axios";
import ExporExcelMiddle from "../../Helpers/ExporExcelMiddle";

export default function DataGridDemo(props) {
  const { DataUser, UpdateId } = props;
  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array

  const [rows, setRows] = useState([]);
  const [totalRows, settotalRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ExportData, setExportData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [signalReload, setsignalReload] = useState(0);
  const GenerateUrl = (url) => {
    let icon = url.split("/");
    let listcion = icon[3].split(".");
    return parseInt(listcion[0], 10);
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    cookies.set("CurrentPageDataPagination", "1", {  path: "/",
      SameSite: "none",
      secure: true,});
    async function GetData() {
      setLoading(true);
      const url = env2.api + endPoint.users;
      const response = await AxiosFetch("GET", url, source);
      if (response?.msg && response.msg.query) {
        let array = [];
        response.msg.result.forEach((element) => {
          array.push({
            id: element._id,
            rol: element.rol,
            firstName: element.firstName,
            lastName: element.lastName,
            nickName: element.firstName + " " + element.lastName,
            actions: element,
            email: element.email,
            urlIma: GenerateUrl(element.urlIma) + 1,
          });
        });
        setRows(array);
        settotalRows(array);
        let NoTotal = array.length;
        setCount(NoTotal);
        let expo = {
          data: array,
          colums: [
            { label: "User", value: "nickName" },
            { label: "Email", value: "email" },
            { label: "Role", value: "rol" },
          ],
        };
        setExportData(expo);
        setLoading(false);
      } else {
        setRows([]);
        settotalRows([]);
        setCount(0);
        setLoading(false);
      }
    }
    GetData();

    return () => {
      source.cancel();
    };
  }, [cookies, signalReload]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = totalRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
    let NoTotal = filteredRows.length;
    setCount(NoTotal);
    setPage(0);
    if (searchValue === "") {
      setRows(totalRows);
      let NoTotal = totalRows.length;
      setCount(NoTotal);
    }
  };
  const Reload = () => {
    setTimeout(() => {
      setsignalReload(signalReload + 1);
    }, 1000);
    UpdateId();
  };

  const columns = [
    {
      field: "img",
      headerName: "Img",
      flex: 0.3,
      minWidth: 30,
      align: "center",
      sortable: false,
      filterable: false,
      renderHeader: () => (
        <Box style={{ color: "#0a0818", textAlign: "center", width: "100%" }}>
          |
        </Box>
      ),
      renderCell: (params) => <>{ImageArray[params.row.urlIma]}</>,
    },

    {
      field: "firstName",
      headerName: "Name",
      flex: 1,
      minWidth: 100,

      renderCell: (params) => (
        <p
          style={{
            minWidth: "100px",
            maxWidth: "15vw",
            overflow: "hidden",
            textAlign: "justify",
            marginLeft: "7px",
            display: "block",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {params.row.firstName + " " + params.row.lastName}
        </p>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "rol",
      headerName: "Role",
      type: "singleSelect",
      flex: 0.5,
      minWidth: 150,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Role</Box>
      ),
      renderCell: (params) => (
        <Chip
          style={{
            textTransform: "capitalize",
            width: "120px",
          }}
          variant="outlined"
          label={params.value}
          color={params.value === "admin" ? "secondary" : "primary"}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      minWidth: 200,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Actions</Box>
      ),
      renderCell: (params) => (
        <>
          <DetailmodalUser
            User={params.row}
            expandButton={false}
            DataUser={DataUser}
            Reload={Reload}
          />
          <Changemodal User={params} DataUser={DataUser} Reload={Reload} />
          <Deletemodal id={params.id} DataUser={DataUser} Reload={Reload} />
        </>
      ),
    },
  ];

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const mobileId = "page-menu-mobile";
  const renderMobileMenu = (
    <Box>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <ExporExcelMiddle
          pageOrigin="Users"
          externalData={ExportData}
          fileName="E.V.E. User Export"
        />
        <Newlmodal DataUser={DataUser} Reload={Reload} />
      </Menu>
    </Box>
  );

  return (
    <Box style={{ width: "100%" }}>
      <ThemeProvider theme={pageTheme}>
        <Typography variant="pageTitle">
          <img src={Icon} alt="" className="rt-icon-header" />
          Users
          <Box
            sx={{
              float: { xs: "none", md: "right" },
              display: { xs: "none", md: "flex" },
            }}
          >
            <ExporExcelMiddle
              pageOrigin="Users"
              externalData={ExportData}
              fileName="E.V.E. User Export"
            />
            <Newlmodal DataUser={DataUser} Reload={Reload} />
          </Box>
          <Box
            sx={{
              float: { xs: "right", md: "none" },
              display: { xs: "inline-block", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVert />
            </IconButton>
          </Box>
        </Typography>
        {renderMobileMenu}
        <Box
          style={{
            width: "100%",
            backgroundColor: "#242933",
            border: " 1px solid #090f1a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "14px",
            marginTop: "24px",
            height: "auto",
          }}
        >
          <div style={{ color: "white", height: "auto", width: "100%" }}>
            <StyledDataGrid
              components={{
                Toolbar: QuickSearchToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                NoRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                NoResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Results Filter Data</div>
                  </GridOverlay>
                ),
                Pagination: () => (
                  <>
                    <TablePagination
                      component="div"
                      page={page}
                      count={count}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={[5, 10, 20]}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      showFirstButton
                      showLastButton
                    />
                  </>
                ),
              }}
              rows={rows}
              columns={columns}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={[5, 10, 20]}
              rowCount={count}
              page={page}
              onPageChange={handleChangePage}
              onPageSizeChange={(PerPage) => setRowsPerPage(PerPage)}
              disableSelectionOnClick
              loading={loading}
              density="comfortable"
              autoHeight
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(""),
                },
              }}
            />
          </div>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
