import React, { useState, useEffect, useCallback } from "react";
import {
  clearRememberMe,
  setRememberMe,
  StorageValues,
  clearStorage,
} from "../../../Helpers/GlobalData";
import "../../../Pages/Login/GeneralStyles.scss";
import jwt_decode from "jwt-decode";

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../../../assets/logo.png";
import Reveald from "../../../assets/reveald.svg";
import {
  ActionButton,
  FormInputTextField,
  PrimaryButton,
  SecondaryButton,
} from "../../../Helpers/Themes";
import { getAuhtentication } from "./Services/AutenticationService";
import UserInput from "../../../Helpers/CommonComponents/UserInput";
import PasswordInputLogin from "../../../Helpers/CommonComponents/PasswordInputLogin";
import { NotifyInfo } from "../../../Helpers/Scripts";

const sign = require("jwt-encode");
const secret = "^HkNZ*AqT$1Nyi_1zVf)pzp0i7y6kz#Uf4%sxs!s4Ae&G5u";

export default function Authentication(props) {
  const { closeSession, OnOkLogin } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [is_remember, setIsRemember] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);

  const [VerificationCode, setVerificationCode] = useState();

  const encode_token_jwt = useCallback(
    (formData) => {
      var date = new Date();
      var time = date.getTime();
      const data = {
        exp: time + 3600,
        email: formData.email,
        password: formData.password,
        iat: time,
      };
      const jwt = sign(data, secret);
      if (is_remember) {
        localStorage.setItem("token-remember-me", jwt);
      }
    },
    [is_remember]
  );

  const login = useCallback(
    async (object) => {
      if (object.type === "submit") {
        object.preventDefault();
      }
      const credentials = {
        email: email === "" ? object.email : email,
        password: password === "" ? object.password : password,
      };
      const response = await getAuhtentication(credentials);
      if (response?.isSuccessResponse || response.response?.OK) {
        encode_token_jwt(credentials);
        var loginStorage = new StorageValues(
          JSON.stringify(response.response.msg)
        );
        loginStorage.save();
        OnOkLogin();
      } else {
        if (
          response.response?.msg ===
          "TOTP is wanted, add the generated value of authPayload"
        ) {
          clearStorage();
          closeSession();
          handleOpenVerify();
        }
      }
    },
    [email, encode_token_jwt, password, OnOkLogin, closeSession]
  );
  const whenComponentDidMount = useCallback(() => {
    var temp_token = localStorage.getItem("token-remember-me");
    if (temp_token) {
      var decoded = jwt_decode(temp_token);
      setEmail(decoded.email);
      setPassword(decoded.password);
      login({ email: decoded.email, password: decoded.password });
    }
    let boolLicense = sessionStorage.getItem("LicenseExpired");
    if (boolLicense) {
      NotifyInfo(
        "Your license has expired, contact your sales agent to renew it."
      );
      sessionStorage.clear();
    }
  }, [login]);
  useEffect(() => {
    whenComponentDidMount();
  }, [whenComponentDidMount]);

  const handleOpenVerify = () => {
    setOpenVerify(true);
  };
  const handleVerify = async (object) => {
    const credentials = {
      email: email === "" ? object.email : email,
      password: password === "" ? object.password : password,
      authPayload:
        VerificationCode === "" ? object.VerificationCode : VerificationCode,
    };
    const response = await getAuhtentication(credentials);
    if (response.isSuccessResponse || response.response.OK) {
      encode_token_jwt(credentials);
      var loginStorage = new StorageValues(
        JSON.stringify(response.response.msg)
      );
      loginStorage.save();
      props.OnOkLogin();
    }
    setTimeout(() => {
      setOpenVerify(false);
    }, 1500);
  };

  const handleChange = (event) => {
    if (event.target.checked === true) {
      setRememberMe();
      setIsRemember(true);
    } else {
      setIsRemember(false);
      localStorage.removeItem("token-remember-me");
      clearRememberMe();
      clearStorage();
    }
  };
  const handleUserName = (value) => {
    setEmail(value);
  };
  const handlePassword = (value) => {
    setPassword(value);
  };
  const handleCloseTOTP = () => {
    setOpenVerify(false);
    setVerificationCode("");
  };
  const handleCodeVerification = (event) => {
    setVerificationCode(event.target.value);
  };

  const MFAModule = (
    <Box
      className="contenido"
      style={{ width: "auto", color: "#FFF", textAlign: "center" }}
    >
      <Box className="header">
        <Typography className="headerTitle" style={{ margin: "15px auto" }}>
          Two Factor Authentication
        </Typography>
        <Typography variant="body1" style={{ margin: "15px auto" }}>
          Enter the MFA code to complete the login
        </Typography>
        <Typography variant="subtitle1" style={{ margin: "15px auto" }}>
          <FormInputTextField
            type="text"
            maxlength="6"
            minlength="6"
            onChange={handleCodeVerification}
            autoFocus
            fullWidth
          ></FormInputTextField>
        </Typography>
      </Box>
      <Box style={{ width: "100%", textAlign: "center" }}>
        <SecondaryButton onClick={handleCloseTOTP}>Cancel</SecondaryButton>
        <ActionButton onClick={handleVerify}>Send</ActionButton>
      </Box>
    </Box>
  );

  return (
    <React.Fragment>
      <Box id="background" className="container-login">
        <Box className="container-login-box">
          <Box className="container-section-logos">
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <img
                  src={Logo}
                  alt="logo-EVE"
                  className="logo-rthreat-modern"
                />
              </Grid>
            </Grid>
          </Box>
          <Modal open={openVerify} onClose={handleVerify}>
            {MFAModule}
          </Modal>
          <Box className="container-section-modern">
            <form onSubmit={login}>
              <Grid container spacing={5}>
                <Grid item xs={12} lg={12}>
                  <UserInput Value={handleUserName} />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <PasswordInputLogin Value={handlePassword} />
                </Grid>
                <Grid item xs={12} lg={7}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          style={{ color: "#c59d48" }}
                          checked={is_remember}
                        />
                      }
                      label="Keep me signed in"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Link className="link" to="/forgot-password">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item xs={12} lg={12} textAlign={"center"}>
                  <PrimaryButton variant="contained" type="submit" fullWidth>
                    Sign In
                  </PrimaryButton>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <img
                    src={Reveald}
                    alt="reveald logo"
                    className="logo-rthreat-modern"
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
          <Typography
            align="center"
            color="white"
            style={{ display: "block", marginTop: "30px" }}
          >
            © 2024 All rights reserved by{" "}
            <a style={{ color: "#c59d48" }} href="https://www.reveald.com">
              Epiphany
            </a>
            {" version 2.6.16.1"}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
}
