import { Delete } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import Eye from "../../../assets/images/eye-icon.svg";
import ArrowIcon from "../../../assets/images/back-arrow-step.svg";
import {
  GetIsolationIpsGroups,
  exportDataGenerate,
  handleCheckListGroup,
  ipListIsChecked,
} from "./services/Functions";
import { useCallback, useEffect, useState } from "react";

import "./styles/ModelIp.scss";
import {
  CustomLoadingOverlay,
  PrimaryButton,
  QuickSearchToolbar,
  SecondaryButton,
  StyledDataGrid,
} from "../../../Helpers/Themes";
import { GridOverlay } from "@mui/x-data-grid-pro";
import GeneralModalDelete from "../../../Helpers/CommonComponents/GeneralDeleteModal";
import CRUDModal from "./components/CRUDModal";
import { DeleteGroupIp, UpdateIps } from "../../../Services/Enviroment";
import {
  checkDisable,
  checkOff,
  checkOn,
  headerStyle,
} from "./constants/styleConstants";
import ExporExcelMiddle from "../../../Helpers/ExporExcelMiddle";

export default function GroupsTable(props) {
  const { DataUser } = props; // defaultIPS contains the server and C2 ip's. DataUser contains the token for the requests

  const [loading, setLoading] = useState(false); //Controls visual rendering of the table while searching and updating the table
  const [SourceData, setSourceData] = useState([]); // State for table data
  const [renderTableContent, setRenderTableContent] = useState([]); // state in charge of displaying the data in the table

  const [searchText, setSearchText] = useState(""); // Filter value
  const [sortModel, setSortModel] = useState([{ field: "name", sort: "asc" }]); //State control sort
  const [rowsPerPage, setRowsPerPage] = useState(10); //State to control row per page
  const [page, setPage] = useState(0); //State to control page
  const [count, setCount] = useState(0); //State count elements in sourceData

  const [openDelete, setOpenDelete] = useState(false); // State control modal delete show
  const [openDeleteGroups, setOpenDeleteGroups] = useState(false); // State control modal delete multigrups to show
  const [selectedGroup, setSelectedGroup] = useState(""); //State to save group to eliminated
  const [activeModal, setActiveModal] = useState(false); // State control modal CRUD
  const [groupData, setGroupData] = useState([]); //Function to save groupdata selected
  const [checkActive, setcheckActive] = useState(false); // state to Show button multi delete

  const [exportData, setExportData] = useState([]); //State to save all data to export

  //Funtion to change page
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  //Function to change sort
  const handleSortModelChange = (newModel) => {
    setSortModel(newModel);
  };
  const getIpGroups = useCallback(async () => {
    setLoading(true);
    let isolationIpsGroups = await GetIsolationIpsGroups(DataUser.token);
    setSourceData(isolationIpsGroups);
    setRenderTableContent(isolationIpsGroups);
    setCount(isolationIpsGroups.length);
    let exportData = exportDataGenerate(isolationIpsGroups);
    setExportData(exportData);
    setLoading(false);
  }, [DataUser]);

  useEffect(() => {
    //ComponentDidMount function to get data
    getIpGroups();
  }, [getIpGroups]);

  const requestSearch = (searchValue) => {
    //function to search group
    setSearchText(searchValue);
    if (searchValue === "") {
      setRenderTableContent(SourceData);
      setCount(SourceData.length);
    } else {
      let filteredRows = [];
      SourceData?.forEach((row) => {
        if (row.name.toUpperCase().indexOf(searchValue.toUpperCase()) > -1) {
          filteredRows.push(row);
        }
      });
      setRenderTableContent(filteredRows);
      setCount(filteredRows.length);
    }
  };
  //columns to show in table
  const GroupsColumns = [
    {
      field: "check",
      sortable: false,
      filterable: false,
      editable: false,
      align: "center",
      renderHeader: (params) => (
        <Box style={headerStyle}>
          <Box
            style={checkActive ? checkOn : checkOff}
            onClick={handleChekedAllGroup}
          />
        </Box>
      ),
      renderCell: (params) => (
        <>
          {params.row.name === "Default" ? (
            <Box style={checkDisable} />
          ) : (
            <Box
              style={params.row.check ? checkOn : checkOff}
              onClick={() => {
                handleCheckedGroup(params.row);
              }}
            />
          )}
        </>
      ),
    },
    {
      field: "name",
      headerName: "Group",
      flex: 1,
      width: 160,
      filterable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 0.5,
      renderCell: (params) => (
        <>
          <Tooltip title="Details">
            <IconButton
              className="iconButtonSize"
              onClick={() => {
                handleCRUDOpenEdit(params.row);
              }}
            >
              <img src={Eye} alt="" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              className="iconButtonSize"
              onClick={handleOpenDelete.bind(this, params.row)}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  //functions to open and close CRUD Modal
  const handleCRUDOpen = () => {
    setActiveModal(true);
  };
  const handleCRUDOpenEdit = (row) => {
    setGroupData(row);
    setActiveModal(true);
  };
  const handleCRUDClose = () => {
    setGroupData([]);
    setActiveModal(false);
  };

  // function for deleting a group
  const handleOpenDelete = (row) => {
    setSelectedGroup(row.name);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setSelectedGroup("");
    setOpenDelete(false);
  };
  const deleteGroup = async () => {
    const IpsData = {
      IPs: [],
      name: selectedGroup,
    };
    Promise.resolve(UpdateIps(IpsData));
    getIpGroups();
    setOpenDelete(false);
  };
  //Functions to delete multigroups
  const handleOpenDeleteGroups = () => {
    setOpenDeleteGroups(true);
  };
  const handleCloseDeleteGroups = () => {
    setOpenDeleteGroups(false);
  };
  const deleteSelectedGroup = async () => {
    let array = [...SourceData];
    array.forEach((element) => {
      if (element.check) {
        const IpsData = {
          IPs: [],
          name: element.name,
        };
        Promise.resolve(DeleteGroupIp(IpsData));
      }
    });
    getIpGroups();
    setOpenDeleteGroups(false);
  };
  //Function to check/uncheck one ip
  const handleCheckedGroup = (row) => {
    let array = [...SourceData];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (element.id === row.id) {
        element.check = !element.check;
        break;
      }
    }
    let checkedList = ipListIsChecked(array);
    setcheckActive(checkedList);
    setRenderTableContent(array);
    setSourceData(array);
    setCount(array.length);
  };

  //Function to check/uncheck all ips
  const handleChekedAllGroup = () => {
    let isChecked = !checkActive;
    let array = handleCheckListGroup(isChecked, SourceData);
    let checkedList = ipListIsChecked(array);
    setcheckActive(checkedList);
    setRenderTableContent(array);
    setSourceData(array);
    setCount(array.length);
  };

  return (
    <>
      <GeneralModalDelete
        BodyText="Do you want to Delete this Group?"
        CancelAction={handleCloseDelete}
        CloseAction={handleCloseDelete}
        ExternalOpen={openDelete}
        HeaderText=""
        PrimaryAction={deleteGroup}
      />
      <GeneralModalDelete
        BodyText="Do you want to Delete the selection?"
        CancelAction={handleCloseDeleteGroups}
        CloseAction={handleCloseDeleteGroups}
        ExternalOpen={openDeleteGroups}
        HeaderText=""
        PrimaryAction={deleteSelectedGroup}
      />
      <CRUDModal
        DataUser={DataUser}
        groupData={groupData}
        handleClose={handleCRUDClose}
        openModalAction={activeModal}
        sourceGroups={SourceData}
        Reload={getIpGroups}
      />
      <Typography variant="pageTitle">
        <IconButton
          onClick={() => {
            props.ReturnPage();
          }}
          className="row-distribution"
        >
          <img src={ArrowIcon} alt="" />
        </IconButton>
        Table of IP Groups
        <Box
          sx={{
            float: { xs: "none", md: "right" },
            display: { xs: "none", md: "flex" },
          }}
        >
          {checkActive ? (
            <SecondaryButton onClick={handleOpenDeleteGroups}>
              Delete
            </SecondaryButton>
          ) : null}
          <PrimaryButton onClick={handleCRUDOpen}>
            New Isolation Group
          </PrimaryButton>
          <ExporExcelMiddle
            pageOrigin={"Groups"}
            externalData={exportData}
            fileName="IP's Export"
          />
        </Box>
      </Typography>

      <div
        style={{
          width: "100%",
          backgroundColor: "#242933",
          border: " 1px solid #090f1a",
          padding: "15px",
          boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
          borderRadius: "14px",
          marginTop: "22px",
        }}
      >
        <div style={{ height: "auto", width: "100%" }}>
          <StyledDataGrid
            autoHeight
            columns={GroupsColumns}
            components={{
              Toolbar: QuickSearchToolbar,
              LoadingOverlay: CustomLoadingOverlay,
              NoRowsOverlay: () => (
                <GridOverlay>
                  <div>No Data Results</div>
                </GridOverlay>
              ),
              NoResultsOverlay: () => (
                <GridOverlay>
                  <div>No Results Filter Data</div>
                </GridOverlay>
              ),
            }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(""),
              },
            }}
            density="compact"
            disableColumnReorder={true}
            disableColumnMenu
            disableSelectionOnClick
            loading={loading}
            onSortModelChange={(newSort) => handleSortModelChange(newSort)}
            onPageChange={handleChangePage}
            onPageSizeChange={(PerPage) => setRowsPerPage(PerPage)}
            page={page}
            pageSize={rowsPerPage}
            pagination
            rows={renderTableContent}
            rowCount={count}
            rowsPerPageOptions={[5, 10, 20]}
            sortModel={sortModel}
          />
        </div>
      </div>
    </>
  );
}
