import React, { useCallback, useEffect, useState } from "react";
/***************Assets from Material UI******************************/
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

/************Assets from component**********************************/
import StepperFromNewExecution from "./StepperFromNewExecution/StepperFromNewExecution";
import StepTypeExecution from "./StepperFromNewExecution/StepTypeExecution/StepTypeExecution";
import StepVectorExecution from "./StepperFromNewExecution/StepVectorExecution/StepVectorExecution";
import StepSelectPackages from "./StepperFromNewExecution/StepSelectPackages/StepSelectPackages";
import StepSelectAgents from "./StepperFromNewExecution/StepSelectAgents/StepSelectAgents";
import {
  pageTheme,
  PrimaryButton,
  SecondaryButton,
} from "../../../Helpers/Themes";
import iconRocketLaunch from "../../../assets/images/launch-icon.svg";
import iconScheduled from "../../../assets/images/schedule-image.svg";
import { AddExecutionAsync } from "../../../Services/Executions";
import { NotifyError, NotifySuccess } from "../../../Helpers/Scripts";
import { ThemeProvider } from "@mui/material";
import StepNameExecution from "./StepperFromNewExecution/StepNameExecution/StepNameExecution";
import { GetEnviromentAsymc } from "../../../Services/Enviroment";

export default function CreatedNewExecutions(props) {
  const { DataUser, openModalGet, AgentConnect } = props;

  const [openModal, setOpenModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedIndexExecution, setSelectedIndexExecution] = useState(0);
  const [valueDate, setValueDate] = useState(
    new Date(new Date().setMinutes(new Date().getMinutes() + 10)).toISOString()
  );
  const [valueVector, setValueVector] = useState("RealExecution");
  const [valueExecution, setvalueExecution] = useState("onDemand");
  const [valueVectorIndex, setValueVectorIndex] = useState(2);
  const [checkedPackages, setCheckedPackages] = useState([]);
  const [checkedPackagesValues, setCheckedPackValues] = useState([]);
  const [nameExecution, setnameExecution] = useState("");
  const [checkedAgents, setCheckedAgents] = useState([]);
  const [checkedAgentsAll, setCheckedAgentsAll] = useState([]);
  const [valueTimeNS, setValueTimeNS] = useState(
    DataUser?.TIMENETWORKSECURITY ? DataUser.TIMENETWORKSECURITY : 5
  );
  const [valueTimeEPP, setValueTimeEPP] = useState(
    DataUser?.TIMEEPP ? DataUser.TIMEEPP : 5
  );
  const [valueTimeRe, setValueTimeRe] = useState(
    DataUser?.TIMEREALEXECUTION ? DataUser.TIMEREALEXECUTION : 5
  );
  const [checkedauto, setCheckedauto] = useState(true);
  const [datagentsConnect, setdatagentsConnect] = useState(AgentConnect);
  const [booflag, setbooflag] = useState(false);
  const [visbleValues, setvisbleValues] = useState("0,0,1");

  const ReloadEnviroments = useCallback(async () => {
    const response = await GetEnviromentAsymc();
    if (response) {
      response.forEach((element) => {
        if (element.name === "EXECUTIONS") {
          element.settings.forEach((setting) => {
            if (setting.variable === "TIMENETWORKSECURITY") {
              setValueTimeNS(setting.value);
            }
            if (setting.variable === "TIMEEPP") {
              setValueTimeEPP(setting.value);
            }
            if (setting.variable === "TIMEREALEXECUTION") {
              setValueTimeRe(setting.value);
            }
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    setValueTimeNS(DataUser.TIMENETWORKSECURITY);
    setValueTimeEPP(DataUser.TIMEEPP);
    setValueTimeRe(DataUser.TIMEREALEXECUTION);
  }, [DataUser]);

  useEffect(() => {
    setdatagentsConnect(AgentConnect);
  }, [AgentConnect]);

  useEffect(() => {
    setOpenModal(openModalGet);
    setValueDate(
      new Date(
        new Date().setMinutes(new Date().getMinutes() + 10)
      ).toISOString()
    );
    setValueTimeNS(DataUser.TIMENETWORKSECURITY);
    setValueTimeEPP(DataUser.TIMEEPP);
    setValueTimeRe(DataUser.TIMEREALEXECUTION);
    if (
      DataUser.TIMENETWORKSECURITY === undefined ||
      DataUser.TIMEEPP === undefined ||
      DataUser.TIMEREALEXECUTION === undefined
    ) {
      ReloadEnviroments();
    }
  }, [openModalGet, DataUser, ReloadEnviroments]);

  const validatingDisabled = (value) => {
    if (value === 1) {
      if (valueDate === null) {
        return true;
      } else {
        return false;
      }
    } else if (value === 2) {
      if (checkedPackages.length === 0) {
        return true;
      } else {
        return false;
      }
    } else if (value === 3) {
      if (valueExecution === "onDemand") {
        if (checkedAgents.length === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (checkedAgentsAll.length === 0) {
          return true;
        } else {
          return false;
        }
      }
    } else if (value === 4) {
      if (nameExecution === "") {
        return true;
      } else {
        return false;
      }
    } else if (value === 0) {
      if (valueExecution === "onDemand") {
        if (AgentConnect.length === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCheckedPackages([]);
    setCheckedPackValues([]);
    setCheckedAgents([]);
    setCheckedAgentsAll([]);
    setValueDate(
      new Date(
        new Date().setMinutes(new Date().getMinutes() + 10)
      ).toISOString()
    );
    setValueVector("RealExecution");
    setvalueExecution("onDemand");
    setSelectedIndexExecution(0);
    setValueVectorIndex(2);
    setnameExecution("");
    setCheckedauto(true);
    setValueTimeNS(DataUser.TIMENETWORKSECURITY);
    setValueTimeEPP(DataUser.TIMEEPP);
    setValueTimeRe(DataUser.TIMEREALEXECUTION);
    setbooflag(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    handleReset();
  };

  const startExecute = (Execution) => {
    if (Execution?._id) {
      // validateAgentsConExecution();
      props.Execute(Execution?._id);
    } else {
      NotifyError("Error an send emulation.");
    }
  };

  const CreateNewPackageExecution = async (execute) => {
    if (valueExecution === "onDemand") {
      var PackageExecutionValuesOnDemand = {
        name: nameExecution,
        fk_package: checkedPackages,
        clients: checkedAgents,
        vector: valueVector,
        typeExecution: valueExecution,
        time_NetworkSecurity: checkedauto
          ? parseInt(DataUser.TIMENETWORKSECURITY)
          : parseInt(valueTimeNS),
        time_EPP: checkedauto
          ? parseInt(DataUser.TIMEEPP)
          : parseInt(valueTimeEPP),
        time_RealExecution: checkedauto
          ? parseInt(DataUser.TIMEREALEXECUTION)
          : parseInt(valueTimeRe),
      };
      const Result = await AddExecutionAsync(
        PackageExecutionValuesOnDemand
      );
      if (Result) {
        NotifySuccess("Emulation created successfully");
        if (execute === true) {
          startExecute(Result);
        }
        handleCloseModal(false);
        props.effectRefresh(true);
      } else {
        setActiveStep(1);
      }
    } else {
      let dateexecche = new Date(valueDate);
      let now = new Date();
      var diffMs = dateexecche - now;
      var diffDays = Math.floor(diffMs / 86400000); // days
      var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

      let dateexec = valueDate.split("T");
      let diaZ = dateexec[0];
      let separate = dateexec[1].split(":");
      let horaZ = separate[0] + ":" + separate[1];
      var PackageExecutionValuesScheduled = {
        name: nameExecution,
        fk_package: checkedPackages,
        clients: checkedAgentsAll,
        vector: valueVector,
        typeExecution: valueExecution,
        startDate: diaZ,
        startTime: horaZ,
        time_NetworkSecurity: checkedauto
          ? parseInt(DataUser.TIMENETWORKSECURITY)
          : parseInt(valueTimeNS),
        time_EPP: checkedauto
          ? parseInt(DataUser.TIMEEPP)
          : parseInt(valueTimeEPP),
        time_RealExecution: checkedauto
          ? parseInt(DataUser.TIMEREALEXECUTION)
          : parseInt(valueTimeRe),
      };
      const Result = await AddExecutionAsync(
        PackageExecutionValuesScheduled
      );

      if (Result) {
        handleCloseModal(false);
        props.effectRefresh(true);
        NotifySuccess(
          "Execution scheduled in " +
            diffDays +
            " days, " +
            diffHrs +
            " hours, " +
            diffMins +
            " minutes"
        );
      } else if (Result === false) {
        setActiveStep(1);
      } else if (Result === undefined) {
        setActiveStep(1);
      }
    }
  };

  const HandleActiveStepNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 3) {
      setbooflag(false);
    }
    if (activeStep === 4) {
      ValidateAgentsConExecution();
    }
  };
  const HandleActiveStepBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep === 4) {
      setbooflag(false);
    }
  };

  const handleTypeExecution = (index, value) => {
    setSelectedIndexExecution(index);
    setvalueExecution(value);
  };

  const handleNameExecution = (value) => {
    value.trim();
    setnameExecution(value);
  };

  const handleVector = (index, vector) => {
    setValueVectorIndex(index);
    setValueVector(vector);
  };

  const handlevisiblesValues = (value) => {
    setvisbleValues(value);
  };

  const handleDate = (date) => {
    setValueDate(date);
  };

  const handleCheckedPackages = (checkedPacks) => {
    setCheckedPackages(checkedPacks);
  };
  const handleCheckedPackValues = (CheckedPackValues) => {
    setCheckedPackValues(CheckedPackValues);
  };

  const handleCheckedAgentsOnline = (checkedAgentOnline) => {
    setCheckedAgents(checkedAgentOnline);
    // setCheckedAgentsRow(checkedAgentOnline);
  };

  const handleCheckedAgentsAll = (checkedAgentAll) => {
    setCheckedAgentsAll(checkedAgentAll);
  };

  const handleTimeValueNSS = (value) => {
    setValueTimeNS(value);
  };

  const handleTimeValueEPP = (value) => {
    setValueTimeEPP(value);
  };
  const handleTimeValueRe = (value) => {
    setValueTimeRe(value);
  };
  const handleChangeAuto = (value) => {
    setCheckedauto(value);
  };

  // validation message insiders

  const [isolationProtectWarning, setIsolationProtectWarning] = useState(false);
  const [atLeastOneInsiderWarning, setAtLeastOneInsiderWarning] =
    useState(false);
  const [allInsiderDesconnetWarning, setAllInsiderDesconnetWarning] =
    useState(false);
  const [allInsiderConnectMessage, setAllInsiderConnectMessage] =
    useState(false);

  const ValidateAgentsConExecution = async () => {
    let agentConID = datagentsConnect.map((element) => element.id);
    let INSOLATIONPROTECTION;

    const response = await GetEnviromentAsymc();
    if (response) {
      response.forEach((element) => {
        if (element.name === "EXECUTIONS") {
          element.settings.forEach((setting) => {
            if (setting.variable === "INSOLATIONPROTECTION") {
              INSOLATIONPROTECTION = setting.value;
            }
          });
        }
      });
    }
    if (INSOLATIONPROTECTION === "Y" && checkedAgents) {
      for (let index = 0; index < checkedAgents.length; index++) {
        const element = checkedAgents[index];
        let inddexJ = -1;
        for (let j = 0; j < datagentsConnect.length; j++) {
          if (datagentsConnect[j].id === element) {
            inddexJ = j;
            break;
          }
        }
        if (inddexJ !== -1) {
          if (
            !datagentsConnect[inddexJ].isolation &&
            datagentsConnect[inddexJ].online &&
            datagentsConnect[inddexJ].OS !== 2
          ) {
            setIsolationProtectWarning(true);
            break;
          }
        }
      }
    }

    if (datagentsConnect.length === 0) {
      setAllInsiderDesconnetWarning(true);
    } else {
      let totalInsiders = 0;
      checkedAgents.forEach((element) => {
        let validate = agentConID.indexOf(element);
        if (validate !== -1) {
          totalInsiders++;
        }
      });
      if (totalInsiders === checkedAgents.length) {
        setAllInsiderConnectMessage(true);
      } else if (totalInsiders < checkedAgents.length && totalInsiders > 0) {
        setAtLeastOneInsiderWarning(true);
      } else {
        setAllInsiderDesconnetWarning(true);
      }
    }
  };

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box
        className="Section"
        style={{
          backgroundColor: "#090f1a",
          border: "1px solid #090f1a",
          boxShadow: "18px 4px 35px rgb(0 0 0 / 15%)",
          borderRadius: "14px",
          padding: "5px",
          width: "75%",
          minHeight: "760px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgb(28, 26, 51)",
            padding: "20px",
            borderRadius: "10px",
            width: "100%",
            height: "100%",
          }}
        >
          <StepperFromNewExecution
            ActiveStep={activeStep}
          ></StepperFromNewExecution>
          <ThemeProvider theme={pageTheme}>
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
                {activeStep === 0 ? (
                  <StepTypeExecution
                    handleNext={HandleActiveStepNext}
                    handleTypeExecution={handleTypeExecution}
                    ValueIndexExecution={selectedIndexExecution}
                    AgentConnect={AgentConnect}
                  />
                ) : activeStep === 1 ? (
                  <StepVectorExecution
                    DataUser={DataUser}
                    handleNext={HandleActiveStepNext}
                    handleBack={HandleActiveStepBack}
                    ValueIndexExecution={selectedIndexExecution}
                    ValueVectorIndex={valueVectorIndex}
                    handleVector={handleVector}
                    handleDate={handleDate}
                    ValueDateGet={valueDate}
                    handleValueTimeNS={handleTimeValueNSS}
                    handleValueTimeEPP={handleTimeValueEPP}
                    handleValueTimeRe={handleTimeValueRe}
                    ValueTimeNS={valueTimeNS}
                    ValueTimeEPP={valueTimeEPP}
                    ValueTimeRe={valueTimeRe}
                    handleChangeAuto={handleChangeAuto}
                    ValueCheckedAuto={checkedauto}
                    valuesVisibles={visbleValues}
                    handlevisiblesValues={handlevisiblesValues}
                  />
                ) : activeStep === 2 ? (
                  <StepSelectPackages
                    DataUser={DataUser}
                    handleNext={HandleActiveStepNext}
                    handleBack={HandleActiveStepBack}
                    handleCheckedPackages={handleCheckedPackages}
                    handleCheckedPackValues={handleCheckedPackValues}
                    ValueIndexExecution={selectedIndexExecution}
                    ValuesCheckedPackages={checkedPackages}
                    ValuesCheckedPackagesValues={checkedPackagesValues}
                  />
                ) : activeStep === 3 ? (
                  <StepSelectAgents
                    DataUser={DataUser}
                    handleNext={HandleActiveStepNext}
                    handleBack={HandleActiveStepBack}
                    handleCheckedAgentsOnline={handleCheckedAgentsOnline}
                    handleCheckedAgentsAll={handleCheckedAgentsAll}
                    ValueIndexExecution={selectedIndexExecution}
                    ValuesCheckedAgentsOnline={checkedAgents}
                    ValuesCheckedAgentsAll={checkedAgentsAll}
                    AgentConnect={AgentConnect}
                  />
                ) : activeStep === 4 ? (
                  <>
                    <StepNameExecution
                      handleNameExecution={handleNameExecution}
                      ValueNameExecution={nameExecution}
                    />
                  </>
                ) : activeStep === 5 ? (
                  <div>
                    {selectedIndexExecution === 0 ? (
                      <Box
                        sx={{
                          height: "510px",
                          textAlign: "center !important",
                          backgroundColor: "rgb(36, 41, 51)",
                          border: "1px solid #1e1c3a",
                          borderRadius: "8px",
                          fontSize: "22px",
                        }}
                      >
                        <Box style={{ textAlign: "center" }}>
                          {isolationProtectWarning ? (
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                            >
                              The Insider(s) doesn't have the protection of the
                              isolation,
                            </Typography>
                          ) : null}
                          {atLeastOneInsiderWarning ? (
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                            >
                              Some of the Insiders are not connected,
                              <br />
                              Are you sure to start?
                            </Typography>
                          ) : null}{" "}
                          {allInsiderDesconnetWarning ? (
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                            >
                              No Insider is currently online,
                              <br />
                              Are you sure to start?
                            </Typography>
                          ) : null}
                          {allInsiderConnectMessage ? (
                            <Typography
                              id="keep-mounted-modal-title"
                              variant="h6"
                            >
                              Would you like to save this configuration or
                              proceed with emulation?
                            </Typography>
                          ) : null}
                          {/* <Typography
                            sx={{
                              mt: 2,
                              mb: 1,
                              textAlign: "center",
                              fontSize: "25px",
                            }}
                          >
                            Would you like to save this configuration or proceed with emulation?
                          </Typography> */}
                        </Box>

                        <Box
                          style={{
                            marginTop: "65px",
                            textAlign: "center",
                            fontSize: "35px",
                          }}
                        >
                          <img
                            style={{
                              width: "200px",
                              borderRadius: "30px",
                              margin: "45px",
                            }}
                            src={iconRocketLaunch}
                            alt=""
                          ></img>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          height: "510px",
                          textAlign: "center !important",
                          backgroundColor: "rgb(36, 41, 51)",
                          border: "1px solid #1e1c3a",
                          borderRadius: "8px",
                          fontSize: "22px",
                        }}
                      >
                        <Box style={{ textAlign: "center" }}>
                          <Typography
                            sx={{
                              mt: 2,
                              mb: 1,
                              textAlign: "center",
                              fontSize: "25px",
                            }}
                          >
                            Do you want to save scheduled?
                          </Typography>
                        </Box>

                        <Box
                          style={{
                            marginTop: "75px",
                            textAlign: "center",
                            fontSize: "35px",
                          }}
                        >
                          <img
                            style={{ width: "290px" }}
                            src={iconScheduled}
                            alt=""
                          />
                        </Box>
                      </Box>
                    )}
                  </div>
                ) : null}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <SecondaryButton
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={HandleActiveStepBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </SecondaryButton>

                {activeStep === 5 && selectedIndexExecution === 0 ? (
                  <Box style={{ float: "right", marginLeft: "20px" }}>
                    <PrimaryButton
                      disabled={booflag}
                      onClick={() => {
                        setbooflag(true);
                        CreateNewPackageExecution(true);
                      }}
                    >
                      Emulate
                    </PrimaryButton>
                  </Box>
                ) : null}

                {activeStep < 5 ? (
                  <PrimaryButton
                    style={{ float: "right" }}
                    disabled={validatingDisabled(activeStep)}
                    onClick={() => {
                      HandleActiveStepNext();
                    }}
                  >
                    Next
                  </PrimaryButton>
                ) : null}

                {activeStep === 5 ? (
                  <PrimaryButton
                    style={{ float: "right" }}
                    disabled={booflag}
                    onClick={() => {
                      setbooflag(true);
                      CreateNewPackageExecution(false);
                    }}
                  >
                    Save emulation
                  </PrimaryButton>
                ) : null}

                {activeStep === 5 ? (
                  <Box style={{ float: "left", marginLeft: "20px" }}>
                    <SecondaryButton onClick={handleReset}>No</SecondaryButton>
                  </Box>
                ) : null}
              </Box>
            </React.Fragment>
          </ThemeProvider>
        </Box>
      </Box>
    </Modal>
  );
}
