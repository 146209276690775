import {
  Box,
  Modal,
  Step,
  StepLabel,
  Stepper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ActionButton,
  ColorlibConnector,
  ColorlibStepIOC,
  FormInputTextField,
  pageTheme,
  PrimaryButton,
  SecondaryButton,
} from "../../../Helpers/Themes";
import DetailmodalAgent from "../../Agents/DetalAgents/DetailAgent";

import WindowIcon from "../../../assets/images/window-icon.svg";
import LinuxIcon from "../../../assets/images/linux-icon.svg";
import MacIcon from "../../../assets/images/apple-logo.svg";
import "./Create.scss";
import TransferListIOC from "./TransferList";
import { addIOCAsync } from "../../../Services/IOCExecution";
import { includes } from "lodash-es";
import { PadNumbers } from "../../../Helpers/GlobalData";
import { NotifyInfo } from "../../../Helpers/Scripts";
import axios from "axios";
import { endPoint } from "../../../Services/EndPoint";
import { env2 } from "../../../Helpers/Instance";
import AxiosFetch from "../../../Services/FetchServices";

const StepLetters = { color: "#fff" };

export default function ModalCreate(props) {
  const { ReloadPage } = props;
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [TotalListOnline, setTotalListOnline] = useState([]);
  const [ListTransfer, setListTransfer] = useState([]);
  const [left, setLeft] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26,
  ]);
  const [SelectEndopoint, setSelectEndopoint] = useState("");
  const [Name, setName] = useState("");
  const [disabledButton, setdisabledButton] = useState(false);
  const [argComm5, setargComm5] = useState(0);
  const [argComm10, setargComm10] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const url = env2.api + endPoint.clients;
    async function GetData() {
      const response = await AxiosFetch("GET", url, source);
      if (response?.msg && response.msg.query) {
        let array = [];
        response.msg.result.forEach((element) => {
          if (element.fk_agent.platform === "Windows") {
            array.push(element);
          }
        });
        setTotalListOnline(array);
      } else {
        setTotalListOnline([]);
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [ReloadPage]);

  const handleClose = () => {
    setActiveStep(0);
    setListTransfer([]);
    setSelectEndopoint("");
    setName("");
    setLeft([
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26,
    ]);
    setargComm5(0);
    setargComm10(0);
    setOpen(false);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleList = (array) => {
    let data = array.length > 0 ? array : [];
    setListTransfer(data);
  };
  const handleSelectAgent = (id) => {
    setSelectEndopoint(id);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const createEXecution = async () => {
    setdisabledButton(true);
    if (TotalListOnline === "Without results" || TotalListOnline.length === 0) {
      setdisabledButton(false);
      NotifyInfo("Endpoint selected is offline");
    } else {
      let array = [];
      ListTransfer.forEach((num) => {
        let argument = [];
        if (num === 5) {
          argument.push(argComm5.toString());
        }
        if (num === 10) {
          argument.push(argComm10.toString());
        }
        array.push({ task: num.toString(), arguments: argument });
      });
      let IOCdata = {
        name: Name,
        tasks: array,
        client: SelectEndopoint,
      };
      const response = await addIOCAsync(
        IOCdata
      );
      if (response === true) {
        props.Reload();
        setdisabledButton(false);
        handleClose();
      } else {
        setdisabledButton(false);
      }
    }
  };
  const handleLeftDataTranfers = (array) => {
    setLeft(array);
  };
  const IOCStepper = (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      <Step key={0}>
        <StepLabel StepIconComponent={ColorlibStepIOC}>
          <Typography style={StepLetters}>Start</Typography>
        </StepLabel>
      </Step>
      <Step key={1}>
        <StepLabel StepIconComponent={ColorlibStepIOC}>
          <Typography style={StepLetters}>IoC commands</Typography>
        </StepLabel>
      </Step>
      <Step key={2}>
        <StepLabel StepIconComponent={ColorlibStepIOC}>
          <Typography style={StepLetters}>Endpoint</Typography>
        </StepLabel>
      </Step>
      <Step key={3}>
        <StepLabel StepIconComponent={ColorlibStepIOC}>
          <Typography style={StepLetters}>Name</Typography>
        </StepLabel>
      </Step>
      <Step key={4}>
        <StepLabel StepIconComponent={ColorlibStepIOC}>
          <Typography style={StepLetters}>Created IoC:</Typography>
        </StepLabel>
      </Step>
    </Stepper>
  );

  const IOCStepStart = (
    <Box className="ContentStepOne">
      {TotalListOnline === "Without results" || TotalListOnline.length === 0 ? (
        <Typography align="center" variant="subTitleContent">
          All Endpoints offline
        </Typography>
      ) : (
        <>
          <Typography variant="subTitleContent">
            Create IoC Validation
          </Typography>
          <Box>
            <PrimaryButton onClick={handleNext}>Start</PrimaryButton>
          </Box>
        </>
      )}
    </Box>
  );
  const IOCStepCommand = (
    <Box className="ContentStepOne">
      <Typography variant="subTitleContent">Select Commands IoC</Typography>
      <Box>
        <TransferListIOC
          valueDataTranfers={ListTransfer}
          DataTranfers={handleList}
          valueLeftTranfers={left}
          valueHandleLeftDataTranfers={handleLeftDataTranfers}
        />
        <FormInputTextField
          id="outlined-number"
          label="Process terminated PID"
          type="number"
          value={argComm5}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={(e) => {
            if (!includes(PadNumbers, e.key)) {
              e.preventDefault();
            }
          }}
          onChange={(event) => {
            if (event.target.value !== ".") {
              setargComm5(event.target.value);
            }
          }}
          InputProps={{ inputProps: { min: 1, max: 999999 } }}
          style={{
            margin: "15px",
            width: "200px",
            display: ListTransfer.includes(5) ? "inline-block" : "none",
          }}
        />
        <FormInputTextField
          id="outlined-number"
          label="Process Access PID"
          type="number"
          value={argComm10}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={(e) => {
            if (!includes(PadNumbers, e.key)) {
              e.preventDefault();
            }
          }}
          onChange={(event) => {
            if (event.target.value !== ".") {
              setargComm10(event.target.value);
            }
          }}
          InputProps={{ inputProps: { min: 1, max: 999999 } }}
          style={{
            margin: "15px",
            width: "200px",
            display: ListTransfer.includes(10) ? "inline-block" : "none",
          }}
        />
      </Box>
      <Box>
        <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
        <PrimaryButton
          onClick={handleNext}
          disabled={ListTransfer.length === 0}
        >
          Next
        </PrimaryButton>
      </Box>
    </Box>
  );
  const IOCStepEndPoints = (
    <Box className="ContentStepOne">
      <Typography variant="subTitleContent">Select Endpoints</Typography>
      <Box className="ContainerCards">
        {TotalListOnline !== "Without results" && TotalListOnline.length > 0 ? (
          TotalListOnline.map((row, index) => {
            return (
              <Box key={index + "-BoxCard"} className="BoxCard">
                <Box
                  key={row._id}
                  className="CardEnpoint"
                  style={{
                    backgroundColor: "#17141d",
                    boxShadow:
                      SelectEndopoint === row._id
                        ? "0 1px 1px 3px #c59d485b"
                        : "0 1px 1px 3px #000",
                  }}
                  onClick={handleSelectAgent.bind(this, row._id)}
                >
                  <Box
                    className="InlineBorder"
                    style={{
                      borderColor:
                        SelectEndopoint === row._id ? "#c59d48" : "#8e97adae",
                    }}
                  >
                    <Box style={{ display: "block" }}>
                      {row.osInformation[0].platform.search("Windows") !==
                      -1 ? (
                        <img src={WindowIcon} alt="" className="IconClass" />
                      ) : null}
                      {row.osInformation[0].platform.search("GNULinux") !==
                      -1 ? (
                        <img src={LinuxIcon} alt="" className="IconClass" />
                      ) : null}
                      {row.osInformation[0].platform.search("Mac") !== -1 ? (
                        <img src={MacIcon} alt="" className="IconClass" />
                      ) : null}

                      <DetailmodalAgent
                        Client={row}
                        SelectIcon={true}
                        DetailsOff={false}
                      />
                      <Typography
                        style={{
                          color:
                            SelectEndopoint === row._id ? "#c59d48" : "#66638f",
                          fontSize: "12px",
                          textAlign: "center",
                          wordBreak: "break-word",
                        }}
                      >
                        {row?.hostname
                          ? row.hostname
                          : row.workUser[0].hostname}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography align="center">Without results</Typography>
        )}
      </Box>
      <Box>
        <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
        <PrimaryButton onClick={handleNext} disabled={SelectEndopoint === ""}>
          Next
        </PrimaryButton>
      </Box>
    </Box>
  );
  const IOCStepName = (
    <Box className="ContentStepOne">
      <Typography variant="subTitleContent">
        Enter Name IoC Validation
      </Typography>
      <Box className="IOCNameContent">
        <FormInputTextField
          label="Name IoC Validation"
          value={Name}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleName}
        />
      </Box>
      <Box>
        <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
        <PrimaryButton onClick={handleNext} disabled={Name === ""}>
          Next
        </PrimaryButton>
      </Box>
    </Box>
  );
  const IOCStepLaunch = (
    <Box className="ContentStepOne">
      <Typography variant="subTitleContent">Select Commands IoC</Typography>
      <Box>
        <Typography>Name : {Name}</Typography>
        <Typography>No Command : {ListTransfer.length}</Typography>
        <Typography>Endpoint : {SelectEndopoint}</Typography>
      </Box>
      <Box>
        <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
        <ActionButton disabled={disabledButton} onClick={createEXecution}>
          Save
        </ActionButton>
      </Box>
    </Box>
  );
  const body = (
    <Box className="ModalSettion">
      <Box className="GeneralContent">
        <>{IOCStepper}</>
      </Box>
      <Box className="GeneralContent">
        {activeStep === 0 ? <>{IOCStepStart}</> : null}
        {activeStep === 1 ? <>{IOCStepCommand}</> : null}
        {activeStep === 2 ? <>{IOCStepEndPoints}</> : null}
        {activeStep === 3 ? <>{IOCStepName}</> : null}
        {activeStep === 4 ? <>{IOCStepLaunch}</> : null}
      </Box>
    </Box>
  );
  return (
    <ThemeProvider theme={pageTheme}>
      <PrimaryButton onClick={handleOpen}>New IoC Validation</PrimaryButton>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </ThemeProvider>
  );
}
