import {
  Box,
  IconButton,
  MenuItem,
  Modal,
  Tab,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  ActionButton,
  SelectCustom,
  FormInputTextField,
} from "../../../Helpers/Themes";
import detailsIcon from "../../../assets/images/eye-icon.svg";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { NotifyError } from "../../../Helpers/Scripts";
import {
  UpdateEmailEnviroment,
  TestEnviromentEmail,
} from "../../../Services/Enviroment";

export default function ModelEmail(props) {
  const { DataSettings } = props;
  const [open, setOpen] = useState(false);
  const [HOST, setHOST] = useState("");
  const [PORT, setPORT] = useState(0);
  const [SECURE, setSECURE] = useState("");
  const [AUTHUSER, setAUTHUSER] = useState("");
  const [AUTHPASS, setAUTHPASS] = useState("");
  const [FROM, setFROM] = useState("");
  const [EMAILTEST, setEMAILTEST] = useState("");
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    setHOST(DataSettings[0].value);
    setPORT(DataSettings[1].value);
    setSECURE(DataSettings[2].value);
    setFROM(DataSettings[3].value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const putUpdateData = async () => {
    const data = {
      HOST: HOST,
      PORT: parseInt(PORT),
      SECURE: SECURE,
      AUTHUSER: AUTHUSER,
      AUTHPASS: AUTHPASS,
      FROM: FROM,
      permanent: "Y",
    };
    await UpdateEmailEnviroment(data);
  };

  const postTestemail = async () => {
    let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (!EMAILTEST.match(regexEmail)) {
      NotifyError("Bad format email");
    } else {
      const data = {
        email: EMAILTEST,
      };
      await TestEnviromentEmail(data);
    }
  };

  const body = (
    <div
      className="Section"
      style={{
        backgroundColor: "#090f1a",
        borderBottom: "1px solid #1e1c3a",
        width: "400px",
      }}
    >
      <Typography align="center" variant="h6">
        Email parameters
      </Typography>
      <Box
        style={{
          width: "100%",
          backgroundColor: "rgb(36,41,51)",
          border: "1px solid #1e1c3a",
          padding: "15px",
          boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
          borderRadius: "12px",
          marginTop: "24px",
          textAlign: "center",
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab style={{ color: "white" }} label="Configure" value="1" />
              <Tab style={{ color: "white" }} label="Test" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Typography>Host Domain</Typography>
            <FormInputTextField
              style={{ margin: "7px" }}
              value={HOST}
              onChange={(event) => {
                if (event.target.value !== " ") {
                  setHOST(event.target.value);
                }
              }}
            />

            <Typography>Port</Typography>
            <FormInputTextField
              style={{ margin: "7px" }}
              type="number"
              value={PORT}
              onChange={(event) => {
                if (event.target.value !== " ") {
                  setPORT(event.target.value);
                }
              }}
              InputProps={{ inputProps: { min: 1, max: 9999 } }}
            />
            <Typography>Active Secure?</Typography>
            <SelectCustom
              fullWidth
              style={{ margin: "7px" }}
              value={SECURE}
              variant="outlined"
              onChange={(event) => {
                if (event.target.value !== " ") {
                  setSECURE(event.target.value);
                }
              }}
            >
              <MenuItem value="0" disabled>
                Select value
              </MenuItem>
              <MenuItem value="Y">Yes</MenuItem>
              <MenuItem value="N">No</MenuItem>
            </SelectCustom>
            <Typography>From email</Typography>
            <FormInputTextField
              style={{ margin: "7px" }}
              value={FROM}
              onChange={(event) => {
                if (event.target.value !== " ") {
                  setFROM(event.target.value);
                }
              }}
            />
            <Typography>Auth user</Typography>
            <FormInputTextField
              style={{ margin: "7px" }}
              value={AUTHUSER}
              onChange={(event) => {
                if (event.target.value !== " ") {
                  setAUTHUSER(event.target.value);
                }
              }}
            />
            <Typography>Auth password</Typography>
            <FormInputTextField
              style={{ margin: "7px" }}
              value={AUTHPASS}
              onChange={(event) => {
                if (event.target.value !== " ") {
                  setAUTHPASS(event.target.value);
                }
              }}
            />

            <ActionButton
              onClick={putUpdateData}
              disabled={HOST === "" || parseInt(PORT) < 1 || FROM === ""}
              style={{ width: "70%", marginLeft: "15%" }}
            >
              Update
            </ActionButton>
          </TabPanel>
          <TabPanel value="2">
            <Typography>Email test</Typography>
            <FormInputTextField
              style={{ margin: "7px" }}
              value={EMAILTEST}
              onChange={(event) => {
                if (event.target.value !== " ") {
                  setEMAILTEST(event.target.value);
                }
              }}
            />
            <ActionButton
              onClick={postTestemail}
              disabled={EMAILTEST === ""}
              style={{ width: "70%", marginLeft: "15%" }}
            >
              Send
            </ActionButton>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <img src={detailsIcon} alt="" />
      </IconButton>

      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
}
